import { connect } from 'react-redux';

// import view
import TopLevelCategory from './topLevelCategory';
// functions to get/set data from redux
import { getCategoriesStatus, getCategoriesData } from '../../store/selectors';
// functions to get data from APIs and save to redux
import { loadCategories } from '../../store/action-creators';

//gets data from redux and sets to variables
// NOTE: categoriesData is actually the full catalogue - categories , sub categories, products
const mapStateToProps = (state) => ({
  categoriesStatus: getCategoriesStatus(state),
  categoriesData: getCategoriesData(state),
});
// pass the api call function to the view
const mapDispatchToProps = {
  loadCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelCategory);