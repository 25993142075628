import { connect } from 'react-redux';

import AdminDataChecker from './adminDataChecker';
import { getOrderBuilderStatus, getOrderBuilderData, getCartStatus, getCartData, getConfigurationGroupStatus, getConfigurationGroupData, getConfigurationGroupLeft, getConfigurationGroupRight, getCustomOptionsGroupStatus, getCustomOptionsGroupData, getCustomOptionsGroupLeft, getCustomOptionsGroupRight } from '../../../orderBuilder/store/selectors';
import { loadOrderBuilder, loadConfigurationGroup, loadCustomOptionsGroup, addOrderToCart } from '../../../orderbuilder/store/action-creators';
import { getCategoriesData } from '../../../storeHome/store/selectors'


const mapStateToProps = (state) => ({
  orderBuilderStatus: getOrderBuilderStatus(state),
  orderBuilderData: getOrderBuilderData(state),
  configurationGroupStatus: getConfigurationGroupStatus(state),
  configurationGroupData: getConfigurationGroupData(state),
  configurationGroupLeft: getConfigurationGroupLeft(state),
  configurationGroupRight: getConfigurationGroupRight(state),
  customOptionsGroupStatus: getCustomOptionsGroupStatus(state),
  customOptionsGroupData: getCustomOptionsGroupData(state),
  customOptionsGroupLeft: getCustomOptionsGroupLeft(state),
  customOptionsGroupRight: getCustomOptionsGroupRight(state),
  cartStatus: getCartStatus(state),
  cartData: getCartData(state),
  categoriesData: getCategoriesData(state),
});

const mapDispatchToProps = {
  loadOrderBuilder,
  loadConfigurationGroup,
  loadCustomOptionsGroup,
  addOrderToCart
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDataChecker);