import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { uniqBy, map, filter, concat, findIndex, find, get } from 'lodash';
import StockOrderProductCard from '../../components/stockOrderProductCard/stockOrderProductCard';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { OrderStatus } from '../../store/constants';
import SidebarNavigation from '../../components/sidebarNavigation/sidebarNavigation';
import BreadcrumbsNavigation from '../../components/breadcrumbsNavigation/breadcrumbsNavigation';
import { Link } from "react-router-dom";
import(/* webpackChunkName: "stock-orders" */'./stockOrders.scss');

const StockOrders = ({
  loadCategories,
  categoriesData,
  categoriesStatus,
  addOrderToCart,
  cartData,
  orderStatus,
  setOrderStatus,
}) => {
  const [productFamily, setProductFamily] = useState(null);
  const [items, setItems] = useState(null);
  const [familyName, setFamilyName] = useState("");
  const { code } = useParams();

  const [productAddedToCart, setProductAddedToCart] = useState();
  const [localRelatedProductsData, setLocalRelatedProductsData] = useState();
  const [alreadyClickProductArray, setAlreadyClickProductArray] = useState([]);
  const [inCartTotalsAdded, setInCartTotalsAdded] = useState(false);

  const [allStockData, setAllStockData] = useState([]);

  // Recursive function to find the product type with the specified code
  const findProductTypeByCode = (productTypes, code) => {
    for (const productType of productTypes) {
      if (productType.Code === code) {
        return productType;
      }
      if (productType.ChildCategories) {
        const result = findProductTypeByCode(productType.ChildCategories, code);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    setProductFamily(null);
    setItems(null);
  }, [code]);

  useEffect(() => {
    if (items) {
      // set up localRelatedProductsData with AddToCartQuantity && QuantityInCart
      var tempLocalRelatedProductsData = items.map(x => {
        x.AddToCartQuantity = 1;
        x.QuantityInCart = 0;
        return x
      });
      if (cartData) {
        tempLocalRelatedProductsData = tempLocalRelatedProductsData.map(x => {
          x.AddToCartQuantity = 1;
          x.QuantityInCart = getQuantityInCart(x);
          return x
        });
        setInCartTotalsAdded(true);

      };
      setLocalRelatedProductsData(tempLocalRelatedProductsData);
    }
  }, [items]);

  useEffect(() => {
    // console.log("categoriesData 1", categoriesData);
    // console.log("code 1", code);
    if (categoriesData) {
      // Reset Product Family and Items before setting them

      // Find the top-level product family 'StockOrders'
      let temporaryProductFamily = find(categoriesData, { 'Code': 'StockOrders' });
      // console.log("temporaryProductFamily", temporaryProductFamily);

      setProductFamily(temporaryProductFamily);
      setFamilyName(temporaryProductFamily?.Name);

      if (temporaryProductFamily?.InverseParentCodeNavigation) {
        temporaryProductFamily = temporaryProductFamily.InverseParentCodeNavigation;
        setProductFamily(temporaryProductFamily);
      }

      if (code) {
        // Use the recursive function to find the product type with the given code
        let temporarySubProductFamily = findProductTypeByCode(temporaryProductFamily, code);
        setFamilyName(temporarySubProductFamily?.Name);

        // Set Items and Product Family
        if (temporarySubProductFamily) {
          setProductFamily(temporarySubProductFamily.ChildCategories);
          setItems(temporarySubProductFamily.Items);
        }
      }
    }
  }, [categoriesData, categoriesStatus, code]);

  useEffect(() => {
    if (loadCategories && code === "StockOrder") {
      loadCategories("StockOrders", categoriesData);
    }
  }, [loadCategories]);

  useEffect(() => {
    if (productFamily) {
      // console.log("productFamily", productFamily)
    }
  }, [productFamily]);

  // DATA FOR SIDEBAR NAVIGATION
  useEffect(() => {
    if (categoriesData) {
      let temporaryStockOrderData = find(categoriesData, { 'Code': 'StockOrders' });
      if (temporaryStockOrderData) {
        let temporaryAllStockData = get(temporaryStockOrderData, "InverseParentCodeNavigation", []);
        if (temporaryAllStockData) {
          setAllStockData(temporaryAllStockData);
        }
      }
    }
  }, [productFamily]);


  const getQuantityInCart = (product) => {
    let quantityInCart = 0;
    var index = findIndex(cartData, { Code: product.Code });
    if (index >= 0) {
      quantityInCart = cartData[index].Quantity;
    }
    return quantityInCart;

  };
  const goToCart = () => {
    history.push({ pathname: "/cart" });
  };
  // handle add to cart
  const handleOnClick = (product) => {
    //console.log("START ADD TO CART");
    let tempCartData = [];
    if (cartData) {
      tempCartData = [...cartData];
    }
    let quantityInCart = 0;
    if (tempCartData.some((e) => e.Code === product.Code)) {
      tempCartData = cartData.map((obj) => {
        if (obj.Code === product.Code) {
          obj.Quantity = obj.Quantity + Number(product.AddToCartQuantity);
          quantityInCart = obj.Quantity;
          //console.log("Existing - New Qty:",obj.Quantity);
          return obj;
        }
        return obj;
      });
    } else {
      product.Quantity = Number(product.AddToCartQuantity);
      //console.log("New Qty:",product.Quantity);
      quantityInCart = product.Quantity;
      product.ItemCode = product.Code;
      product.Type = "Accessories";
      tempCartData.push(product);
    }
    addOrderToCart(tempCartData);
    setOrderStatus(OrderStatus.STOCK_ORDER);
    updateQuantityInCart(product, quantityInCart);
    const newAlreadyClickProductArray = concat(alreadyClickProductArray, product.Code);
    setAlreadyClickProductArray(newAlreadyClickProductArray);
  };


  // handle change in AddToCartQuantity for item
  const startChangeQuantity = (event, item) => {
    let tempLocalRelatedProductsData = [...localRelatedProductsData];
    // Find item index in array of items in cart by using the item code of the item passed into function
    var index = findIndex(tempLocalRelatedProductsData, { Code: item.Code });
    let tempItem = item;
    // Update the quanitity to value inputed by user
    tempItem.AddToCartQuantity = event.target.value;
    //console.log("Changing AddToCartQuantity:", index,tempItem);
    // Replace item at index using native splice
    tempLocalRelatedProductsData.splice(index, 1, tempItem);
    // set updated array to state
    setLocalRelatedProductsData(tempLocalRelatedProductsData);
    //console.log("localRelatedProductsData: ",localRelatedProductsData);
  };


  const updateQuantityInCart = (item, quantityInCart) => {
    let tempLocalRelatedProductsData = [...localRelatedProductsData];
    // Find item index in array of items in cart by using the item code of the item passed into function
    var index = findIndex(tempLocalRelatedProductsData, { Code: item.Code });
    let tempItem = item;
    // Update the quanitity to value inputed by user
    tempItem.QuantityInCart = Number(quantityInCart);
    // Replace item at index using native splice
    tempLocalRelatedProductsData.splice(index, 1, tempItem);
    // set updated array to state
    setLocalRelatedProductsData(tempLocalRelatedProductsData);
  };


  const handleConfigureOnClick = (product) => {
    //console.log("productAddedToCart",productAddedToCart)
    const earpiecesPath = "/store/Earpieces/" + productAddedToCart.CategoryCode + "/" + productAddedToCart.ModelTypeCode;
    history.push({
      pathname: earpiecesPath,
      state: { product: product },
    });
  };


  return (
    <div className="stock-orders-main-div" id="stock-orders">
      <div className="stock-orders-content">
        {orderStatus == OrderStatus.REGULAR &&
          <div className="alert alert-danger" role="alert">
            <p>You cannot add items from the Stock Order section because you have an ongoing Regular order. Please clear your cart before proceeding.</p>
          </div>
        }
        {(productFamily || items) &&
          <Row>
            <Col xs={11}>
              <BreadcrumbsNavigation allStockData={allStockData} code={code} />
              <h1>{familyName ? familyName : "Stock / Consumables"}</h1>
            </Col>
            <Col xs={1} className="mt-1">
              <Link to="/cart" className="btn btn-primary">Checkout</Link>
            </Col>
          </Row>
        }
      </div>
      {(productFamily || items) &&
        <Row>
          <Col xs={3}>
            <SidebarNavigation allStockData={allStockData} code={code} />
          </Col>
          <Col xs={9}>
            {code === "Serenity" && 
              <div className='alert alert-info'>
                <p>Phonak Serenity choice universal fit hearing protection is intended to be purchased over the counter in hearing clinics. Each box includes various sized plugs (S,M,L) to support a good fit for your clients. They are not intended for trial, and therefore are not returnable to Phonak for credit. These products have a shelf-life, check the box for expiry date.</p>
              </div>
            }
            {productFamily && productFamily.length > 0 &&
              <Row className="stock-orders-cards">
                {productFamily.map((product, index) => (
                  <StockOrderProductCard product={product} key={index} index={index} />
                ))}
              </Row>
            }
            {items &&
              <div className='order-builder-main-div related-products-main-div acc-rgr-chg-main-div'>
                <Row className="related-product-cards">
                  {items.map((product, index) => {
                    return (
                      <Col
                        md={3}
                        xs={12}
                        key={product.Code}
                        className="related-product-cols"
                      >
                        <div className="related-product-card">
                          <div className="related-product-title-div">
                            <h3 className="related-product-category-title">
                              {product.Name}
                            </h3>
                          </div>
                          <div className="related-product-img">
                            <span className="helper"></span>
                            <img
                              src={product.ImagePath}
                              className="img-fluid"
                            />
                          </div>
                          <p>{product.Code}     {product.QuantityInCart > 0 ? <span><ShoppingCartIcon />{product.QuantityInCart}</span> : ""}</p>
                          {product.CategoryCode == "Earpieces" ? (
                            <a
                              onClick={() => handleConfigureOnClick(product)}
                              className="btn btn-secondary related-product-button"
                            >
                              Configure
                            </a>
                          ) : (
                            <div className="add-related-to-cart">
                              <Form.Control
                                type="number"
                                name="quantity"
                                defaultValue={product.AddToCartQuantity}
                                onChange={(event) =>
                                  startChangeQuantity(event, product)
                                }
                                min="1"
                                className="me-2"
                                aria-label="Stock"
                              />
                              <a
                                onClick={orderStatus === OrderStatus.REGULAR ? undefined : () => handleOnClick(product)}
                                className={`btn btn-primary related-product-button ${orderStatus === OrderStatus.REGULAR ? 'disabled-link' : ''}`}
                              >Add to cart</a>
                            </div>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            }
          </Col>
        </Row>
      }
    </div>
  )
};

StockOrders.propTypes = {
  loadCategories: PropTypes.func,
  loadStockOrders: PropTypes.func,
  categoriesData: PropTypes.array,
}

export default StockOrders;
