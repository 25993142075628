import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { OrderStatus } from "../storeHome/store/constants";
import RICBTEItemBuilder from "./components/RICBTEItemBuilder/RICBTEItemBuilder";
import ITEItemBuilder from "./components/ITEItemBuilder/ITEItemBuilder";
import EarPiecesItemBuilder from "./components/EarPiecesItemBuilder/EarPiecesItemBuilder";
import AccRgrChgItemBuilder from "./components/AccRgrChgItemBuilder/AccRgrChgItemBuilder";
import RepairItemBuilder from "./components/RepairItemBuilder/RepairItemBuilder";
import CustomHearingItemBuilder from "./components/CustomHearingItemBuilder/CustomHearingItemBuilder";
import { find } from 'lodash';
import(/* webpackChunkName: "orderBuilder" */ "./orderBuilder.scss");

const OrderBuilder = ({
  loadOrderBuilder,
  orderBuilderStatus,
  orderBuilderData,
  configurationGroupStatus,
  configurationGroupData,
  configurationGroupLeft,
  configurationGroupRight,
  loadConfigurationGroup,
  customOptionsGroupStatus,
  customOptionsGroupData,
  customOptionsGroupLeft,
  customOptionsGroupRight,
  loadCustomOptionsGroup,
  categoriesData,
  cartStatus,
  addOrderToCart,
  cartData,
  orderStatus,
  setOrderStatus,
}) => {
  const { categoryId, productId, itemId } = useParams();
  const [builderType, setBuilderType] = useState();
  //console.log("Cat and prod:", categoryId,"-",productId, "-",itemId);
  useEffect(() => {
    if (loadOrderBuilder && categoriesData) {
      // console.log("categoriesData - 2", categoriesData)
      const productArray = find(categoriesData, { "Code": categoryId })

      if (productArray.InverseParentCodeNavigation.length > 0) {
        if (productId == "AccRgrChg") {
          const productType = find(productArray.InverseParentCodeNavigation, function (o) {
            return o.ParentCode === productId;
          })
          loadOrderBuilder(productType.Type, productId);
        } else {
          const productType = find(productArray.InverseParentCodeNavigation, function (o) {
            return o.Code === productId;
          })
          loadOrderBuilder(productType.Type, productId);
        }
      }
      else {
        loadOrderBuilder(categoryId, productId);
      }
    }
  }, [loadOrderBuilder, categoriesData]);

  useEffect(() => {
    if (orderBuilderData) {
      if (orderBuilderData.Category) {
        if (orderBuilderData.Category.Type) {
          setBuilderType(orderBuilderData.Category.Type);
        }
      }
    }
  }, [orderBuilderData])

  if (categoryId === "Repair") {
    // IF Repair selected from the home page / navbar, displays Repair builder
    return (
      <RepairItemBuilder
        cartData={cartData}
        categoriesData={categoriesData}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        loadOrderBuilder={loadOrderBuilder}
      />
    );
  }

  if (orderStatus == OrderStatus.STOCK_ORDER) {
    return (
      <div className="order-builder-main-div">
        <h1>{categoryId === "Earpieces" && "Earpieces"}{categoryId === "AccRgrChg" && "Accessories / Roger / Chargers"}</h1>
        <div className="alert alert-danger" role="alert">
          <p>You cannot add items from this section because you have an ongoing stock order. Please clear your cart before proceeding.</p>
        </div>
      </div>
    )
  }

  //if (productId === "Earpiece") {
  if (categoryId === "Earpieces") {
    // IF earpieces selected from the home page / navbar, displays ear piece builder
    return (
      <EarPiecesItemBuilder
        categoriesData={categoriesData}
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
        loadOrderBuilder={loadOrderBuilder}
        setOrderStatus={setOrderStatus}
      />
    );
  }

  //if (productId === "Earpiece") {
  if (categoryId === "SerenityC") {
    // IF earpieces selected from the home page / navbar, displays ear piece builder
    return (
      <CustomHearingItemBuilder
        categoriesData={categoriesData}
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
        loadOrderBuilder={loadOrderBuilder}
        setOrderStatus={setOrderStatus}
      />
    );
  }


  if (categoryId === "AccRgrChg") {
    // IF AccRgrChg selected from the home page / navbar, displays AccRgrChg builder
    return (
      <AccRgrChgItemBuilder
        cartData={cartData}
        categoriesData={categoriesData}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        loadOrderBuilder={loadOrderBuilder}
        setOrderStatus={setOrderStatus}
      />
    );
  }



  if (builderType === "ITE") {
    // IF item is an ITE hearing aid displayed ITE builder
    return (
      <ITEItemBuilder
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
        setOrderStatus={setOrderStatus}
      />
    );
  }


  // If item is not an ITE hearing aid or ear piece, displays RIC/BTE builder
  return (
    <RICBTEItemBuilder
      cartData={cartData}
      cartStatus={cartStatus}
      addOrderToCart={addOrderToCart}
      orderBuilderStatus={orderBuilderStatus}
      orderBuilderData={orderBuilderData}
      setOrderStatus={setOrderStatus}
    />
  );
};

OrderBuilder.propTypes = {
  loadOrderBuilder: PropTypes.func,
  orderBuilderStatus: PropTypes.string,
  orderBuilderData: PropTypes.any,
  configurationGroupStatus: PropTypes.string,
  configurationGroupData: PropTypes.object,
  configurationGroupLeft: PropTypes.object,
  configurationGroupRight: PropTypes.object,
  loadConfigurationGroup: PropTypes.func,
  customOptionsGroupStatus: PropTypes.string,
  customOptionsGroupData: PropTypes.object,
  customOptionsGroupLeft: PropTypes.array,
  customOptionsGroupRight: PropTypes.array,
  loadCustomOptionsGroup: PropTypes.func,
  categoriesData: PropTypes.array,
  cartStatus: PropTypes.string,
  addOrderToCart: PropTypes.func,
  cartData: PropTypes.array,
  orderStatus: PropTypes.string,
};

export default OrderBuilder;
