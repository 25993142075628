import { connect } from 'react-redux';

import Cart from './cart';
import { getCartStatus, getCartData } from './store/selectors';
import { addOrderToCart } from '../orderBuilder/store/action-creators';
import { setOrderStatus } from '../storeHome/store/action-creators';
// import { loadCart } from './store/action-creators';


const mapStateToProps = (state) => ({
  cartStatus: getCartStatus(state),
  cartData: getCartData(state)
});

const mapDispatchToProps = {
  addOrderToCart,
  setOrderStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);