import React , { useEffect, useState } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import logoUnitron from "../../assets/img/logo-unitron.svg";
import clientLogo from "../../assets/img/xs-specsavers-logo-opt.svg";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NavbarLinks from '../NavbarLinks/NavbarLinks';
import(/* webpackChunkName: "nav-bar" */ "./navbar.scss");

export default function UTopNavbar() {
  let history = useHistory();
  const [ profileData, setProfileData] = useState();

  useEffect(() => {
    let profileDataTemp = localStorage.getItem("profileData");
    profileDataTemp = JSON.parse(profileDataTemp);
    setProfileData(profileDataTemp);
  }, []);

  const logout = () => {
    const cookies = new Cookies();
    //console.log("logout");
    const loginToken = cookies.get("LoginToken");
    if (loginToken) {
      //console.log("logout loginToken", loginToken);
      cookies.remove("LoginToken");
      localStorage.clear();
      history.push({ pathname: "/login" });
    }
  };

  return (
    <div className="top-navbar notprintable">
      <Container>
        <Row>
          <Col xs={2}>
            <div className="navbar-logo">
              <Link className="navbar-brand navbar-unitron-brand" to="/">
                <img src={logoUnitron} className="img-fluid unitron-logo" />
              </Link>
            </div>
          </Col>
          <Col md={4}></Col>
          <Col xs={6}>
            <div>
              <div className='float-right'>
                  <p className='float-right navbar-phone-link'>Contact Unitron NZ <a href='tel:+6494889841'>+64 9 488 9841</a></p>
                <Nav className="me-auto float-right top-navbar-links">
                  <Link className="nav-link" to="/profile">
                    <PermIdentityIcon />
                  </Link>
                  <Link className="nav-link" to="/cart">
                    <ShoppingCartIcon />
                  </Link>
                  <Nav.Link onClick={logout}>Logout</Nav.Link>
                </Nav>
 
              </div>
              {profileData && profileData.OrganisationName == "SpecSavers" &&   
                <div className='float-right client-logo'>
                    <img src={clientLogo}/>
                </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bottom-navbar">
        <NavbarLinks />
      </div>
    </div>
  );
}
