// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from '../../orderConfirmation/store/action-creators';
import { LOAD_ORDER_CONFIRMATION, RESET_ORDER_NUMBER } from '../../orderConfirmation/store/actions';
import { SUBMIT_REPAIR_ORDER } from './actions';
import { get } from 'lodash';
import { OrderConfirmationStatus } from '../../orderConfirmation/store/constants';
import api from '../../../utilities/api';
import moment from 'moment';

export function* rootSaga() {
  yield takeLatest(SUBMIT_REPAIR_ORDER, submitRepairOrder);
  yield takeLatest(LOAD_ORDER_CONFIRMATION, loadOrderConfirmation);
  yield takeLatest(RESET_ORDER_NUMBER, resetOrderNumber);
  
}

export function* loadOrderConfirmation() {
  try {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.LOADING));

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken? This will logout if there is no token. Handled in the private layout
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    const response = yield call(api.get, '/api/order/timelines', {headers:headerParams});
    yield put(actionCreators.setOrderConfirmationData(response.data));

    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}

export function* resetOrderNumber() {
  try {
    // yield console.log("reset order number STARTT")
    yield put(actionCreators.setOrderNumber(""));
    // yield console.log("reset order number END")

  }
  catch(error) {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}

export function* submitRepairOrder(data) {
  try {
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.LOADING));
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    // console.log('data passed in to saga', data);
    const repairRemakeFormObject = get(data, "payload", {})
    
    // const orderItems = get(data, "payload", []);
    const customShippingInfo = get(data, "0", "");
    const ponumber = get(data, "1", "");
    const organisationCode = get(data, "2", "");
    const siteId = get(data, "3", 0);
    const firstName = get(data, "4", "");
    const lastName = get(data, "5", "");
    const requiredByDate = get(data, "6", "");
    const hafsOrder = get(data, "7", "");
    const childHAFSRepair = get(data, "8", "");
    const VAClient = get(data, "9", false);
    const rightModel = get(data, "10", []);
    const rightModelCode = get(data, "11", []);
    const leftModel = get(data, "12", []);
    const leftModelCode = get(data, "13", []);

    
    const LeftHearingInstruments = get(repairRemakeFormObject, "LeftHearingInstruments", false);
    const RightHearingInstruments = get(repairRemakeFormObject, "RightHearingInstruments", false);
    const LeftSerialNumber = get(repairRemakeFormObject, "LeftEarmouldShells", "");
    const RightSerialNumber = get(repairRemakeFormObject, "RightEarmouldShells", "");
    const AccessoriesCount = get(repairRemakeFormObject, "AccessoriesCount", "");
    const RogerCount = get(repairRemakeFormObject, "RogerCount", "");
    const ChargerOptionsCount = get(repairRemakeFormObject, "ChargerOptionsCount", "");
    const ReasonForRepair = get(repairRemakeFormObject, "ReasonForRepair", "");
    const ReasonForReshellOrRemake = get(repairRemakeFormObject, "ReasonForReshellOrRemake", "");
    const IsCurrentPhonakHearingAidWearer = get(repairRemakeFormObject, "IsCurrentPhonakHearingAidWearer", false);
    const AdditionalInformation = get(repairRemakeFormObject, "AdditionalInformation", "");
    const IsEnable = get(repairRemakeFormObject, "IsEnable", false);
    const IsInsurance = get(repairRemakeFormObject, "IsInsurance", false);
    const IsChildHafsRepair = get(repairRemakeFormObject, "IsChildHafsRepair", false);
    const IsVeteransAffairsClient = get(repairRemakeFormObject, "IsVeteransAffairsClient", false);
    
    // console.log("ReasonForReshellOrRemake", ReasonForReshellOrRemake);

    const specialInstructions = get(data, "", "");
    const orderId = get(data, "", 0);
    const orderNumber = get(data, "", "");
    const userId = get(data, "", 0);
    const orderType = get(data, "", "");
    const orderDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const files = get(data, "", []);
    const gensolveId = get(data, "", "");
    const orderNotes = get(data, "", ""); 
    const discountCode = get(data, "", "");
    const discountOption = get(data, "", "");
    const trackingStatus = get(data, "", "");
    const trackingNumber = get(data, "", "");
    const deliveryDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const invoiceNumber = get(data, "", "");
    const invoiceDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const invoiceAmount = get(data, "", 0);
    const isStockOrder = get(data, "", false);
    const tamperProofRequired = get(data, "", "");
    const dateOfBirth = get(data, "", "");

    var bodyFormData = new FormData();
    yield bodyFormData.append('ClientDateOfBirth', dateOfBirth);
    yield bodyFormData.append('ClientFirstName', firstName);
    yield bodyFormData.append('ClientLastName', lastName);
    yield bodyFormData.append('CustomShippingInfo', customShippingInfo);
    yield bodyFormData.append('DeliveryDate', deliveryDate);
    yield files.forEach(function(file, index) {
      bodyFormData.append('Files', file);
    });
    yield bodyFormData.append('GensolveId', gensolveId);
    yield bodyFormData.append('InvoiceAmount', invoiceAmount);
    yield bodyFormData.append('InvoiceDate', invoiceDate);
    yield bodyFormData.append('InvoiceNumber', invoiceNumber);
    yield bodyFormData.append('IsHafs', hafsOrder.toString());
    yield bodyFormData.append('IsStock', isStockOrder.toString());
    yield bodyFormData.append('OrderId', orderId);
    yield bodyFormData.append('OrderItems', "");
    yield bodyFormData.append('OrderNotes', orderNotes);
    yield bodyFormData.append('OrderNumber', orderNumber);
    yield bodyFormData.append('OrganisationCode', organisationCode);
    yield bodyFormData.append('OrderDate', orderDate.toString());
    yield bodyFormData.append('Ponumber', ponumber);
    yield bodyFormData.append('RequiredByDate', requiredByDate);
    yield bodyFormData.append('SiteCode', siteId);
    yield bodyFormData.append('SpecialInstructions', specialInstructions);
    yield bodyFormData.append('TamperProofRequired', tamperProofRequired.toString());
    yield bodyFormData.append('TrackingNumber', trackingNumber);
    yield bodyFormData.append('TrackingStatus', trackingStatus);
    yield bodyFormData.append('VAClient', VAClient);
    yield bodyFormData.append('DiscountCode', discountCode);
    yield bodyFormData.append('DiscountOption', discountOption);
    yield bodyFormData.append('UserId', userId);
    
    yield bodyFormData.append('LeftHearingInstruments', LeftHearingInstruments.toString());
    yield bodyFormData.append('RightHearingInstruments', RightHearingInstruments.toString());
    yield bodyFormData.append('LeftSerialNumber', LeftSerialNumber.toString());
    yield bodyFormData.append('RightSerialNumber', RightSerialNumber.toString());
    yield bodyFormData.append('AccessoriesCount', AccessoriesCount.toString());
    yield bodyFormData.append('RogerCount', RogerCount.toString());
    yield bodyFormData.append('ChargerOptionsCount', ChargerOptionsCount.toString());
    yield bodyFormData.append('ReasonForRepair', ReasonForRepair);
    yield bodyFormData.append('ReasonForReshellOrRemake', JSON.stringify(ReasonForReshellOrRemake));
    yield bodyFormData.append('IsCurrentPhonakHearingAidWearer', IsCurrentPhonakHearingAidWearer.toString());
    yield bodyFormData.append('AdditionalInformation', AdditionalInformation);
    yield bodyFormData.append('IsEnable', IsEnable.toString());
    yield bodyFormData.append('IsInsurance', IsInsurance.toString());
    yield bodyFormData.append('IsChildHafsRepair', childHAFSRepair.toString());
    yield bodyFormData.append('IsVeteransAffairsClient', IsVeteransAffairsClient.toString());
    yield bodyFormData.append('OrderType', "RepairRemake");
    yield bodyFormData.append('RightModelCode', rightModelCode);
    yield bodyFormData.append('LeftModelCode', leftModelCode);
    
    const response = yield call(api.post, '/api/order', bodyFormData, {headers:headerParams});
    // console.log("order confirmation response", response);
    if(response.data){
      yield put(actionCreators.setOrderNumber(response.data));
      // console.log("setOrderNumber");
    }
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.SUCCESS));
  }
  catch(error) {
    // console.log("order confirmation response", error);
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}