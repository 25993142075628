import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import(/* webpackChunkName: "sidebar-navigation" */ "./sidebarNavigation.scss");

const SidebarNavigation = ({ allStockData, code }) => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [childCategories, setChildCategories] = useState([]);

  // Recursive function to find the category by code
  const findCategoryByCode = (categories, code) => {
    for (let category of categories) {
      if (category.Code === code) {
        return category;
      }
      if (category.ChildCategories && category.ChildCategories.length > 0) {
        const foundCategory = findCategoryByCode(category.ChildCategories, code);
        if (foundCategory) {
          return foundCategory;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (allStockData) {
      if (code === "StockOrder") {
        // Show all top-level categories under Stock / Consumables
        setCurrentCategory(null);
        setParentCategory(null);
        const topLevelCategories = allStockData.filter(
          (category) => category.ParentCode === null
        );
        setChildCategories(topLevelCategories);
        // console.log("Top Level Categories:", topLevelCategories);
      } else {
        // Determine the current category using recursive search
        const currentCat = findCategoryByCode(allStockData, code);
        // console.log("Current Category:", currentCat);

        if (currentCat) {
          setCurrentCategory(currentCat);

          // Determine the parent category
          const parentCat = findCategoryByCode(
            allStockData,
            currentCat.ParentCode
          );
          // console.log("Parent Category:", parentCat);
          setParentCategory(parentCat);

          // Show child categories of the current category
          if (currentCat.ChildCategories && currentCat.ChildCategories.length > 0) {
            setChildCategories(currentCat.ChildCategories);
          } else {
            setChildCategories([]);
          }
        } else {
          setCurrentCategory(null);
          setParentCategory(null);
          setChildCategories([]);
        }
      }
    }
  }, [allStockData, code]);

  return (
    <div className="sidebar">
      <p>Categories</p>
      {childCategories && childCategories.length > 0 && 
        <hr />
      }
      <ul>
        {childCategories.map((category) => (
          <li key={category.Code}>
            <Link to={`/store/StockOrders/${category.Code}`}>
              {category.Name}
            </Link>
          </li>
        ))}
      </ul>
      {(parentCategory || code !== 'StockOrder') && (
        <div className="back-to-parent">
          <hr />
          <Link to={`/store/StockOrders/${parentCategory ? parentCategory.Code : 'StockOrder'}`}>
            &larr; Back to {parentCategory ? parentCategory.Name : 'Stock / Consumables'}
          </Link>
        </div>
      )}
    </div>
  );
};

export default SidebarNavigation;
