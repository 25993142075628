import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { OrderBuilderStatus, CartStatus, ConfigurationGroupStatus, CustomOptionsStatus } from './constants';

const orderBuilderStatus = createReducer({
  [actions.SET_ORDER_BUILDER_STATUS]: (state, { payload }) => payload
}, OrderBuilderStatus.IDLE);

const orderBuilderData = createReducer({
  [actions.SET_ORDER_BUILDER_DATA]: (state, { payload }) => payload
}, OrderBuilderStatus.IDLE);

const configurationGroupStatus = createReducer({
  [actions.SET_CONFIGURATION_GROUP_STATUS]: (state, { payload }) => payload
}, ConfigurationGroupStatus.IDLE);

const configurationGroupData = createReducer({
  [actions.SET_CONFIGURATION_GROUP_DATA]: (state, { payload }) => payload
}, ConfigurationGroupStatus.IDLE);

const configurationGroupLeft = createReducer({
  [actions.SET_CONFIGURATION_GROUP_LEFT]: (state, { payload }) => payload
}, ConfigurationGroupStatus.IDLE);

const configurationGroupRight = createReducer({
  [actions.SET_CONFIGURATION_GROUP_RIGHT]: (state, { payload }) => payload
}, ConfigurationGroupStatus.IDLE);

const customOptionsGroupsStatus = createReducer({
  [actions.SET_CUSTOM_OPTIONS_GROUP_STATUS]: (state, { payload }) => payload
}, CustomOptionsStatus.IDLE); // Updated this line

const customOptionsGroupsData = createReducer({
  [actions.SET_CUSTOM_OPTIONS_GROUP_DATA]: (state, { payload }) => payload
}, CustomOptionsStatus.IDLE);

const customOptionsGroupLeft = createReducer({
  [actions.SET_CUSTOM_OPTIONS_GROUP_LEFT]: (state, { payload }) => payload
}, CustomOptionsStatus.IDLE);

const customOptionsGroupRight = createReducer({
  [actions.SET_CUSTOM_OPTIONS_GROUP_RIGHT]: (state, { payload }) => payload
}, CustomOptionsStatus.IDLE);

const cartStatus = createReducer({
  [actions.SET_CART_STATUS]: (state, { payload }) => payload
}, CartStatus.IDLE);

const cartData = createReducer({
  [actions.SET_CART_DATA]: (state, { payload }) => payload
}, CartStatus.IDLE);

export default combineReducers({
  orderBuilderStatus,
  orderBuilderData,
  cartStatus,
  cartData,
  configurationGroupStatus,
  configurationGroupData,
  configurationGroupLeft,
  configurationGroupRight,
  customOptionsGroupsStatus,
  customOptionsGroupsData,
  customOptionsGroupLeft,
  customOptionsGroupRight,
});
