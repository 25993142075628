import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { uniqBy, map, filter, concat, merge, get, find, forEach } from "lodash";
import { Row, Col } from "react-bootstrap";
//Components
import Loading from "../../../../components/Loading/Loading";
import Model from "../RICBTEModel/Model";
import PerformanceLevel from "../RICBTEPerformanceLevel/PerformanceLevel";
import Colour from "../RICBTEColour/Colour";
import HowMany from "../RICBTEHowMany/HowMany";
import Receivers from "../RICBTEReceivers/Receivers";
import Domes from "../RICBTEDomes/Domes";

//Constants
import { CartStatus, OrderBuilderStatus } from "../../store/constants";
import { OrderStatus } from "../../../storeHome/store/constants";


//SIMILAR TO OTHER TWO ITEM BUILDER COMPONENTS BUT LESS CUSTOM OPTIONS
const RICBTEItemBuilder = ({ cartStatus, addOrderToCart, orderBuilderStatus, orderBuilderData, cartData, setOrderStatus, }) => {
  let history = useHistory();

  const { productId } = useParams();
  const [modelForOrder, setModelForOrder] = useState();
  const [modelTypeCodes, setModelTypeCodes] = useState();

  const [performanceLevelForOrder, setPerformanceLevelForOrder] = useState();
  const [performanceLevelCodes, setPerformanceLevelCodes] = useState();

  const [colourForOrder, setColourForOrder] = useState();
  const [colourCodes, setColourCodes] = useState();

  const [howManyForOrder, setHowManyForOrder] = useState(1);
  const [leftorRight, setLeftOrRight] = useState("");

  const [receiversForOrder, setReceiversForOrder] = useState();
  const [receivers, setRecievers] = useState();
  const [fullListOfReceivers, setFullListOfReceivers] = useState();

  const [domesForOrder, setDomesForOrder] = useState([]);
  const [domes, setDomes] = useState();

  const [orderItems, setOrderItems] = useState();
  const [currentItemForOrder, setCurrentItemForOrder] = useState();

  const [itemName, setItemName] = useState();
  const [itemsToAddToCart, setItemsToAddToCart] = useState();

  const [relatedItemGroupCode, setRelatedItemGroupCode] = useState();
  
  const [pageDescription, setPageDescription] = useState();

  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    
    //If product was successfully added to cart move user to related-products page.
    if (cartStatus === CartStatus.SUCCESS && orderItems) {
      //console.log("RICBTEItemBuilder - OrderItems: ",orderItems)
      history.push({ 
        pathname: `/related-products/${orderItems[0].Code}` ,
        state: { productsAddedToCart: itemsToAddToCart },      
      });
    }
  }, [cartStatus]);

  useEffect(() => {
    
    //console.log("RICBTEItemBuilder - receivers",receivers)
  }, [receivers]);

  const startAddOrderToCart = () => {
    let tempErrorMessages = [];
    setErrorMessages();
    if(!modelForOrder) {
      tempErrorMessages.push("Please select a model");
      setErrorMessages(tempErrorMessages);
    }
    if (leftorRight === "") {
      tempErrorMessages.push("Please select at least one side");
      setErrorMessages(tempErrorMessages);
    }
    if(!performanceLevelForOrder) {
      tempErrorMessages.push("Please select a performance level");
      setErrorMessages(tempErrorMessages);
    }
    if(!colourForOrder) {
      tempErrorMessages.push("Please select a colour");
      setErrorMessages(tempErrorMessages);
    }
    if(!colourForOrder || !performanceLevelForOrder || !modelForOrder || leftorRight === "") {
      return
    }
    

    let order = orderItems;

    //Setting Item code and quantity for main item
    //console.log("RICBTEItemBuilder - order", order);
    const colourName = find(colourCodes, {Code: order[0].ColourCode}, "");
    //console.log("RICBTEItemBuilder - colourCode", colourName, colourCodes, order[0].ColourCode, colourCodes[0]);

    var addValuesForOrder = {
      Quantity: 1,
      ItemCode: order[0].Code,
      ColourName: colourName.Name,
      ColourRgb: colourName.Rgb,
      Type: "Standard",
    };
    merge(order[0], addValuesForOrder);

    //Changing quantity if user selected 2 in how many component
    // if (howManyForOrder == 2) {
    //   order[0].Quantity = 2;
    // }

    // Setting sides selected by user, already checked if empty
    order[0].Side = leftorRight; 
    if(leftorRight == "B") {
      order[0].Quantity = 2;
    }

    //Adding quantity and item code for domes selected in domes component
    domesForOrder && domesForOrder.forEach((dome) => {
      dome.Quantity = 1;
      dome.ItemCode = dome.Code;
    });

    //Adding quantity and item code for receivers selected in receivers component
    receiversForOrder && receiversForOrder.forEach((dome) => {
      dome.Quantity = 1;
      dome.ItemCode = dome.Code;
    });
    var itemsToAdd = concat(order, domesForOrder, receiversForOrder);
    setItemsToAddToCart(itemsToAdd);
    let currentCart = [];

    //Checking current cart to see if items are already in the cart 
    if (cartData) {
      currentCart = [...cartData];

      //Looping through domes selected
      //Either adding them to cart if they arent currently in the cart.
      //Or increasing quantity by 1 if the same item is already in the cart.
      domesForOrder && domesForOrder.forEach((dome) => {
        if (currentCart.some((e) => e.Code === dome.Code)) {
          currentCart = cartData.map((obj) => {
            if (obj.Code === dome.Code) {
              obj.Quantity = obj.Quantity + 1;
              return obj;
            }
            return obj;
          });
        } else {
          dome.Quantity = 1;
          dome.ItemCode = dome.Code;
          currentCart.push(dome);
        }
      });

      //Looping through receivers selected
      //Either adding them to cart if they arent currently in the cart.
      //Or increasing quantity by 1 if the same item is already in the cart.
      receiversForOrder && receiversForOrder.forEach((receiver) => {
        if (currentCart.some((e) => e.Code === receiver.Code)) {
          currentCart = cartData.map((obj) => {
            if (obj.Code === receiver.Code) {
              obj.Quantity = obj.Quantity + 1;
              return obj;
            }
            return obj;
          });
        } else {
          receiver.Quantity = 1;
          receiver.ItemCode = receiver.Code;
          currentCart.push(receiver);
        }
      });

      //Either adding order to cart if it's not already in the cart.
      //Or increasing quantity by 1 if the same item is already in the cart.
      if (currentCart.some((e) => e.Code === order[0].Code)) {
        currentCart = cartData.map((obj) => {
          if (obj.Code === order[0].Code) {
            obj.Quantity = obj.Quantity + 1;
            return obj;
          }
          return obj;
        });
      } else {
        currentCart.push(order[0]);
      }
    }
    else {
      currentCart = concat(order, domesForOrder, receiversForOrder);
    }
    setOrderStatus(OrderStatus.REGULAR);
    addOrderToCart(currentCart);
  };

  useEffect(() => {
    if (orderBuilderStatus === OrderBuilderStatus.SUCCESS) {
      const itemList = get(orderBuilderData, "Items", []);
      // let tempItemName = itemList[0];
      // tempItemName = get(tempItemName, "Name", "Item Builder");
      // setItemName(tempItemName);

      const category = get(orderBuilderData, "Category", {});
      if(category)
      {
            let tempItemName = category.Name;
          setItemName(tempItemName);
          setPageDescription(category.Description);
    }
      //Get uniqueModelTypeCodes from data
      let uniqueModelTypeCodes = itemList;
      uniqueModelTypeCodes = filter(uniqueModelTypeCodes, { CategoryCode: productId });
      uniqueModelTypeCodes = uniqBy(uniqueModelTypeCodes, "ModelTypeCode");
      uniqueModelTypeCodes = map(uniqueModelTypeCodes, "ModelTypeCode");

      let uniqueModelTypes = get(orderBuilderData, "Models",[]);
      let modelTypeArray = [];

      forEach(uniqueModelTypeCodes, function (modelType) {
        let modelTypeObject = find(uniqueModelTypes, {"Code": modelType});

        
        modelTypeArray.push(modelTypeObject);
      });

      setModelTypeCodes(modelTypeArray);

      let domesInData = [];
      const domesList = get(orderBuilderData, "Domes", []);
      domesInData = filter(domesList, { CategoryCode: "PH004003" });
      //console.log("RICBTEItemBuilder - domesInData:",domesInData);
      setDomes(domesInData);

      let recieversInData = [];
      const receiversList = get(orderBuilderData, "Receivers", []);
      //recieversInData = filter(receiversList, { CategoryCode: "PH004001" });
      // do not filter here - assume that those items in that category are receivers
      recieversInData = receiversList;
      //console.log("recieversInData",recieversInData);
      
      setFullListOfReceivers(recieversInData);
    }
  }, [orderBuilderStatus]);

  //SET UNIQUE PERFORMANCE LEVELS
  useEffect(() => {
    if (modelForOrder) {
      //Get all items with modelTypeSelected
      const itemList = get(orderBuilderData, "Items", []);

      let orderBuilderDataAfterOrderType = filter(itemList, {
        ModelTypeCode: modelForOrder,
      });

      //Get uniquePerformanceLevelForItemBuilder
      let uniquePerformanceLevelForItemBuilder = orderBuilderDataAfterOrderType;
      uniquePerformanceLevelForItemBuilder = uniqBy(
        uniquePerformanceLevelForItemBuilder,
        "PerformanceLevelCode"
      );
      uniquePerformanceLevelForItemBuilder = map(
        uniquePerformanceLevelForItemBuilder,
        "PerformanceLevelCode"
      );
      //Removes nulls
      uniquePerformanceLevelForItemBuilder = filter(
        uniquePerformanceLevelForItemBuilder,
        (v) => v
      );
      let uniquePerformanceLevels = get(orderBuilderData, "PerformanceLevels", []);

      let performanceLevelArray = [];
      forEach(uniquePerformanceLevelForItemBuilder, function (performanceLevel) {
        let performanceLevelObject = find(uniquePerformanceLevels, {"Code": performanceLevel});

        if(performanceLevelObject) {
          performanceLevelArray.push(performanceLevelObject);
        }
      });
      setPerformanceLevelCodes(performanceLevelArray);
    }
  }, [modelForOrder]);

  //SET UNIQUE COLOURS
  useEffect(() => {
    setRelatedItemGroupCode();
    if (performanceLevelForOrder && modelForOrder) {
      const itemList = get(orderBuilderData, "Items", []);

      let orderBuilderDataAfterOrderType = filter(itemList, {
        ModelTypeCode: modelForOrder,
      });
      let orderBuilderDataAfterPerformanceLevel = filter(
        orderBuilderDataAfterOrderType,
        { PerformanceLevelCode: performanceLevelForOrder }
      );


      //Get uniquePerformanceLevelForOrder
      let uniqueColoursForOrder = orderBuilderDataAfterPerformanceLevel;
      uniqueColoursForOrder = uniqBy(uniqueColoursForOrder, "ColourCode");
      uniqueColoursForOrder = map(uniqueColoursForOrder, "ColourCode");
      // setColourCodes(uniqueColoursForOrder);
      
      let uniqueColour = get(orderBuilderData, "Colours", []);
      let colourArray = [];
      forEach(uniqueColoursForOrder, function (colour) {
        let colourObject = find(uniqueColour, {"Code": colour});

        if(colourObject) {
          colourArray.push(colourObject);
        }
      });

      setColourCodes(colourArray);
    }
  }, [performanceLevelForOrder, modelForOrder]);

  useEffect(() => {
    if(modelForOrder, performanceLevelForOrder, colourForOrder) {
      let currentItem = get(orderBuilderData, "Items", []);
      currentItem = find(currentItem, {ModelTypeCode: modelForOrder, PerformanceLevelCode: performanceLevelForOrder, ColourCode: colourForOrder}, [])
      setCurrentItemForOrder(currentItem);
    }
  }, [modelForOrder, performanceLevelForOrder, colourForOrder]);

  //SET PRODUCT SELECTED
  useEffect(() => {
    if (performanceLevelForOrder && modelForOrder && colourForOrder) {
      const itemList = get(orderBuilderData, "Items", []);
      let orderBuilderDataAfterOrderType = filter(itemList, {
        ModelTypeCode: modelForOrder,
      });
      //console.log("RICBTEItemBuilder - 1 - ItemList after ModelType (" + modelForOrder + "): ", orderBuilderDataAfterOrderType);

      let orderBuilderDataAfterPerformanceLevel = filter(
        orderBuilderDataAfterOrderType,
        { PerformanceLevelCode: performanceLevelForOrder }
      );
      //console.log("RICBTEItemBuilder - 2 -  - ItemList after Performance level (" + performanceLevelForOrder + "): ", orderBuilderDataAfterPerformanceLevel);

      let orderBuilderDataAfterColour = filter(
        orderBuilderDataAfterPerformanceLevel,
        { ColourCode: colourForOrder }
      );
      //console.log("RICBTEItemBuilder - 3 -  - ItemList after Colour (" + colourForOrder + "): ", orderBuilderDataAfterColour);
      setOrderItems(orderBuilderDataAfterColour);
      if(orderBuilderDataAfterColour[0].RelatedItemGroupCode){
        // console.log("orderBuilderDataAfterColour[0].RelatedItemGroupCode", orderBuilderDataAfterColour[0].RelatedItemGroupCode)
        setRelatedItemGroupCode(orderBuilderDataAfterColour[0].RelatedItemGroupCode);
      }
    }
  }, [performanceLevelForOrder, modelForOrder, colourForOrder]);

  useEffect(() => {
    //console.log("relatedItemGroupCode -------------------- ", relatedItemGroupCode)
    if(relatedItemGroupCode) {
      const receiversList = get(orderBuilderData, "Receivers", []);
      let receiversTemp = filter(receiversList, {"RelatedItemGroupCode" : relatedItemGroupCode}, []);
      // console.log("RICBTEItemBuilder.js - orderBuilderData: ", orderBuilderData);
      // console.log("RICBTEItemBuilder.js - receiversList: ", receiversList);
      // console.log("RICBTEItemBuilder.js - receiversTemp: ", receiversTemp);
      receiversTemp = uniqBy(receiversTemp, "PerformanceLevelCode");
      //console.log("RICBTEItemBuilder.js - Updating receivers: ", receiversTemp);
      setRecievers(receiversTemp);
    }
    else {
      setRecievers([]);
    }
  }, [relatedItemGroupCode, modelForOrder, colourForOrder, performanceLevelForOrder])

  return (
    <div className="order-builder-main-div">
      {orderBuilderStatus === OrderBuilderStatus.LOADING && <Loading />}
      {orderBuilderStatus === OrderBuilderStatus.SUCCESS && (
        <div>
          <h1>{itemName}</h1>
          {orderBuilderData && (
            <div>
              {modelTypeCodes && (
                <Model
                  modelTypeCodes={modelTypeCodes}
                  setModelForOrder={setModelForOrder}
                />
              )}
              {performanceLevelCodes && (
                <PerformanceLevel
                  performanceLevelCodes={performanceLevelCodes}
                  setPerformanceLevelForOrder={setPerformanceLevelForOrder}
                />
              )}
              {colourCodes && (
                <Colour
                  colourCodes={colourCodes}
                  setColourForOrder={setColourForOrder}
                />
              )}
              <HowMany
                howManyForOrder={howManyForOrder}
                setHowManyForOrder={setHowManyForOrder}
                leftorRight={leftorRight}
                setLeftOrRight={setLeftOrRight}
              />
              {receivers && receivers.length > 0 && (
                <Receivers
                  receivers={receivers}
                  fullListOfReceivers={fullListOfReceivers}
                  setReceiversForOrder={setReceiversForOrder}
                  receiversForOrder={receiversForOrder}
                />
              )}
              {domes && domes.length > 0 && (
                <Domes domes={domes} setDomesForOrder={setDomesForOrder} />
              )}
              {errorMessages && errorMessages.length > 0 && (
                <Row className="order-builder-component-title">
                  <Col xs={12} className="alert alert-danger">
                    <p>Please resolve the following errors</p>
                    <ul>
                      {errorMessages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              )}
              <div className="item-builder-add-to-cart-button">
                <a className="btn float-right" onClick={startAddOrderToCart}>
                  Add to order
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RICBTEItemBuilder;