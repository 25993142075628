import React, { useEffect ,useState} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import RICBTEItemBuilder from "../../../orderbuilder/components/RICBTEItemBuilder/RICBTEItemBuilder";
import ITEItemBuilder from "../../../orderbuilder/components/ITEItemBuilder/ITEItemBuilder";
import EarPiecesItemBuilder from "../../../orderbuilder/components/EarPiecesItemBuilder/EarPiecesItemBuilder";
import(/* webpackChunkName: "orderBuilder" */ "../../../orderbuilder/orderBuilder.scss");

const AdminDataChecker = ({
  loadOrderBuilder,
  orderBuilderStatus,
  orderBuilderData,
  configurationGroupStatus,
  configurationGroupData,
  configurationGroupLeft,
  configurationGroupRight,
  loadConfigurationGroup,
  customOptionsGroupStatus,
  customOptionsGroupData,
  customOptionsGroupLeft,
  customOptionsGroupRight,
  loadCustomOptionsGroup,
  categoriesData,
  cartStatus,
  addOrderToCart,
  cartData,
}) => {
  const { categoryId, productId } = useParams();
  const [outputjs, setOutputjs] = useState("");
  //Items Models PerformanceLevels Colours Domes Receivers
  const [category, setCategory] = useState({});
  const [items, setItems] = useState([]);
  const [models, setModels] = useState([])
  const [performanceLevels, setPerformanceLevels] = useState([])
  const [colours, setColours] = useState([])
  const [domes, setDomes] = useState([])
  const [receivers, setReceivers] = useState([])

  useEffect(() => {
    if (loadOrderBuilder) {
      //console.log("before load order builder", categoryId);
      // loadOrderBuilder(categoryId);
      // Call to saga to load this sections data
      loadOrderBuilder(categoryId, productId);
    }
  }, [loadOrderBuilder]);
  useEffect(() => {
    if (loadOrderBuilder) {
      //console.log("before load order builder", categoryId);
      // loadOrderBuilder(categoryId);
      // Call to saga to load this sections data
      // let newjs = JSON.stringify(orderBuilderData, null, 2);
      // setOutputjs(newjs);
      // //console.log(newjs);
       let newjs2 = JSON.stringify(orderBuilderData, null, 2);
       if(newjs2)
       {
        let js = JSON.stringify(orderBuilderData.Category, null, 2);
        //js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");
        setCategory(js);

        //Items Models PerformanceLevels Colours Domes Receivers
        js = JSON.stringify(orderBuilderData.Items, null, 2);
        if(js) {js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");}
        setItems(js);

        js = JSON.stringify(orderBuilderData.Models, null, 2);
        if(js) {js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");}
        setModels(js);

        js = JSON.stringify(orderBuilderData.PerformanceLevels, null, 2);
        if(js) {js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");}
        setPerformanceLevels(js);

        js = JSON.stringify(orderBuilderData.Colours, null, 2);
        if(js) {js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");}
        setColours(js);

        js = JSON.stringify(orderBuilderData.Domes, null, 2);
        if(js) {js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");}
        setDomes(js);

        js = JSON.stringify(orderBuilderData.Receivers, null, 2);
        if(js) {js=js.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");}
        setReceivers(js);
        // //console.log("orderBuilderData:", orderBuilderData);
        // setOutputjs(newjs2);
        //  newjs2=newjs2.replace(/{/g, "<br />{&nbsp;&nbsp;&nbsp;");      
        //  setOutputjs(newjs2);
       }
    }
  }, [orderBuilderData]);
  //Items Models PerformanceLevels Colours Domes Receivers
if(orderBuilderData)
{
return (
  <div><h1>Data Checker</h1>
  <h2>Category</h2>
  <div dangerouslySetInnerHTML={{__html: category}} />
  <h2>Models</h2>
   <table>
   <thead>
    <tr><td>Code</td><td>ModelCode</td><td>Name</td><td>CategoryCode</td><td>ImagePath</td><td>ConfigurationGroupCode</td><td>CustomOptionGroupCode</td></tr>
    </thead>
    <tbody>
      {orderBuilderData.Models.map((item, index) => (
          <tr key={index} >  
            <td>{item.Code}</td><td>{item.ModelCode}</td><td>{item.Name}</td><td>{item.CategoryCode}</td><td>{item.ImagePath}</td><td>{item.ConfigurationGroupCode}</td><td>{item.CustomOptionGroupCode}</td>
          </tr>
        ))
      }
      </tbody>
  </table>
   <h2>Items</h2>
  {/* <!--"Code": "050-0361-T7", "Name": "Phonak Audeo B90-13 - Alpine White", "ParentCode": null, "PerformanceLevelCode": "B90", "ColourCode": "T7", "ImagePath": "x", "ModelTypeCode": "Audeo_B13", "CategoryCode": "Audeo_B", "RelatedItemGroupCode": null, "Description": null, "PackSize": null --> */}
<div style={{maxHeight:'400px',overflowY:'scroll'}}>
  <table >
    <thead>
    <tr>
        <td>Code</td><td>Name</td><td>ParentCode</td><td>PerformanceLevelCode</td><td>ColourCode</td>
      <td>ImagePath</td><td>ModelTypeCode</td><td>CategoryCode</td><td>RelatedItemGroupCode</td><td>PackSize</td>
    </tr></thead>
    <tbody>
      {orderBuilderData.Items.map((item, index) => (
          <tr key={index} >  
              <td>{item.Code}</td><td>{item.Name}</td><td>{item.ParentCode}</td><td>{item.PerformanceLevelCode}</td><td>{item.ColourCode}</td>
              <td>{item.ImagePath}</td><td>{item.ModelTypeCode}</td><td>{item.CategoryCode}</td><td>{item.RelatedItemGroupCode}</td><td>{item.PackSize}</td>
          </tr>
        ))
      }
      </tbody>
  </table>
  </div>
  {/* <div dangerouslySetInnerHTML={{__html: items}} /> */}
  
  
  <h2>PerformanceLevels</h2>
  <div dangerouslySetInnerHTML={{__html: performanceLevels}} />
  <h2>Colours</h2>
  <div dangerouslySetInnerHTML={{__html: colours}} />
  <h2>Domes</h2>
  <div style={{maxHeight:'400px',overflowY:'scroll'}}>
  <table >
    <thead>
    <tr>
        <td>Code</td><td>Name</td><td>ParentCode</td><td>PerformanceLevelCode</td><td>ColourCode</td>
      <td>ImagePath</td><td>ModelTypeCode</td><td>CategoryCode</td><td>RelatedItemGroupCode</td><td>PackSize</td>
    </tr></thead>
    <tbody>
      {orderBuilderData.Domes && orderBuilderData.Domes.map((item, index) => (
          <tr key={index} >  
              <td>{item.Code}</td><td>{item.Name}</td><td>{item.ParentCode}</td><td>{item.PerformanceLevelCode}</td><td>{item.ColourCode}</td>
              <td>{item.ImagePath}</td><td>{item.ModelTypeCode}</td><td>{item.CategoryCode}</td><td>{item.RelatedItemGroupCode}</td><td>{item.PackSize}</td>
          </tr>
        ))
      }
      </tbody>
  </table>
  </div>
  {/* <div dangerouslySetInnerHTML={{__html: domes}} /> */}
  <h2>Receivers</h2>
  <div style={{maxHeight:'400px',overflowY:'scroll'}}>
  <table >
    <thead>
    <tr>
        <td>Code</td><td>Name</td><td>ParentCode</td><td>PerformanceLevelCode</td><td>ColourCode</td>
      <td>ImagePath</td><td>ModelTypeCode</td><td>CategoryCode</td><td>RelatedItemGroupCode</td><td>PackSize</td>
    </tr></thead>
    <tbody>
      {orderBuilderData.Receivers && orderBuilderData.Receivers.map((item, index) => (
          <tr key={index} >  
              <td>{item.Code}</td><td>{item.Name}</td><td>{item.ParentCode}</td><td>{item.PerformanceLevelCode}</td><td>{item.ColourCode}</td>
              <td>{item.ImagePath}</td><td>{item.ModelTypeCode}</td><td>{item.CategoryCode}</td><td>{item.RelatedItemGroupCode}</td><td>{item.PackSize}</td>
          </tr>
        ))
      }
      </tbody>
  </table>
  </div>
  {/* <div dangerouslySetInnerHTML={{__html: receivers}} /> */}
  {/* <div><pre>{ outputjs}</pre></div> */}
</div>

)
}
  if (categoryId === "PH001002") {
    // IF item is an ITE hearing aid displayed ITE builder
    return (
      <ITEItemBuilder
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
      />
    );
  }

  if (categoryId === "PH007") {
    // IF earpieces selected from the home page / navbar, displays ear piece builder
    return (
      <EarPiecesItemBuilder
        categoriesData={categoriesData}
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
        loadOrderBuilder={loadOrderBuilder}
      />
    );
  }

  // If item is not an ITE hearing aid or ear piece, displays RIC/BTE builder
  return (
    <RICBTEItemBuilder
      cartData={cartData}
      cartStatus={cartStatus}
      addOrderToCart={addOrderToCart}
      orderBuilderStatus={orderBuilderStatus}
      orderBuilderData={orderBuilderData}
    />
  );
};

AdminDataChecker.propTypes = {
  loadOrderBuilder: PropTypes.func,
  orderBuilderStatus: PropTypes.string,
  orderBuilderData: PropTypes.any,
  configurationGroupStatus: PropTypes.string,
  configurationGroupData: PropTypes.object,
  configurationGroupLeft: PropTypes.object,
  configurationGroupRight: PropTypes.object,
  loadConfigurationGroup: PropTypes.func,
  customOptionsGroupStatus: PropTypes.string,
  customOptionsGroupData: PropTypes.object,
  customOptionsGroupLeft: PropTypes.array,
  customOptionsGroupRight: PropTypes.array,
  loadCustomOptionsGroup: PropTypes.func,
  categoriesData: PropTypes.array,
  cartStatus: PropTypes.string,
  addOrderToCart: PropTypes.func,
  cartData: PropTypes.array,
};

export default AdminDataChecker;
