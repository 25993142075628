import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { head, find } from "lodash";
import(
  /* webpackChunkName: "order-builder-ite-shell-colour" */ "./iteShellColour.scss"
);
import InsertLink from "@material-ui/icons/InsertLink";
import LinkOff from "@material-ui/icons/LinkOff";
import Done from "@material-ui/icons/Done";
import testModel from "../../../../assets/img/ModelTest.png";

const ITEShellColour = ({
  setITEShellColourForOrder,
  iteShellColourRight,
  iteShellColourLeft,
  iteFaceplateColourLeft,
  iteFaceplateColourRight,
  activeRightITEShellColour,
  setActiveRightITEShellColour,
  activeLeftITEShellColour,
  setActiveLeftITEShellColour,
  activeRightITEFaceplate,
  setActiveRightITEFaceplate,
  activeLeftITEFaceplate,
  setActiveLeftITEFaceplate,
  leftSelected,
  rightSelected,
  selectedShellStyleRight,
  selectedShellStyleLeft,
  iteModelLinked,
}) => {
  const [linked, setLinked] = useState(true);

  // Set default active values
  useEffect(() => {
    // console.log("iteShellColourRight", iteShellColourRight)
    // console.log("iteShellColourLeft", iteShellColourLeft)
    if (iteShellColourRight) {
      const initalActive = head(iteShellColourRight, {});
      setRightActive(initalActive);
    }
    if (iteShellColourLeft) {
      const initalActive = head(iteShellColourLeft, {});
      setLeftActive(initalActive);
    }
    if (iteFaceplateColourRight) {
      let initalActive = head(iteFaceplateColourRight, {});
      if (selectedShellStyleRight && selectedShellStyleRight.Code == "cShell") {
        let tempInitalActive = find(iteFaceplateColourLeft, function (colour) { return colour.Code == "26"; });
        if (tempInitalActive) {
          initalActive = tempInitalActive;
        }
        //console.log("initalActive", initalActive);
      }
      setActiveRightITEFaceplate(initalActive);
    }
    if (iteFaceplateColourLeft) {
      let initalActive = head(iteFaceplateColourLeft, {});
      if (selectedShellStyleLeft && selectedShellStyleLeft.Code == "cShell") {
        let tempInitalActive = find(iteFaceplateColourLeft, function (colour) { return colour.Code == "26"; });
        if (tempInitalActive) {
          initalActive = tempInitalActive;
        }
        //console.log("initalActive", initalActive);
      }
      setActiveLeftITEFaceplate(initalActive);
    }
  }, [
    iteShellColourRight,
    iteShellColourLeft,
    iteFaceplateColourRight,
    iteFaceplateColourLeft,
    selectedShellStyleRight,
    selectedShellStyleLeft,
  ]);

  useEffect(() => {
    if (activeRightITEShellColour || activeLeftITEShellColour) {
      const shellColours = {
        right: activeRightITEShellColour,
        left: activeLeftITEShellColour,
      };
      // setITEShellColourForOrder(shellColours);
    }
  }, [activeRightITEShellColour, activeLeftITEShellColour]);


  // Set shell colour and faceplate colour for order
  // Set other side if still linked
  const setRightActive = (shellColour) => {
    setActiveRightITEShellColour(shellColour);
    if (linked && iteModelLinked) {
      setActiveLeftITEShellColour(shellColour);
    }
  };

  const setLeftActive = (shellColour) => {
    setActiveLeftITEShellColour(shellColour);
    if (linked && iteModelLinked) {
      setActiveRightITEShellColour(shellColour);
    }
  };

  const setRightFaceplateActive = (shellColour) => {
    setActiveRightITEFaceplate(shellColour);
    if (linked && iteModelLinked) {
      setActiveLeftITEFaceplate(shellColour);
    }
  };

  const setLeftFaceplateActive = (shellColour) => {
    setActiveLeftITEFaceplate(shellColour);
    if (linked && iteModelLinked) {
      setActiveRightITEFaceplate(shellColour);
    }
  };

  return (
    <div>
      {(iteShellColourRight || iteShellColourLeft) &&
        (rightSelected || leftSelected) && (
          <div className="ite-shell-colour-component-main-div order-builder-component">
            <Row className="order-builder-component-title">
              <Col xs={4}>
                <h2>Choose colours</h2>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col md={5}>
                    {rightSelected && (
                      <div className="text-right">
                        <h3>Right Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    {rightSelected && leftSelected && (
                      <div className="text-center link-div">
                        {linked && iteModelLinked ? (
                          <InsertLink
                            className="linked"
                            onClick={() => setLinked(false)}
                          />
                        ) : (
                          <LinkOff
                            onClick={() => setLinked(!linked)}
                            className="unlinked"
                          />
                        )}
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    {leftSelected && (
                      <div>
                        <h3>Left Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    {rightSelected && (
                      <div>
                        <Row className="ite-shell-colour-row-right">
                          <Col xs={12}>
                            {iteShellColourRight && iteShellColourRight.length > 0 &&
                              <p>
                                Shell Colour:{" "}
                                {activeRightITEShellColour && (
                                  <strong>
                                    {activeRightITEShellColour.Name} -{" "}
                                    {activeRightITEShellColour.Code}
                                  </strong>
                                )}
                              </p>
                            }
                          </Col>
                          {iteShellColourRight && iteShellColourRight.map((shellColour, index) => {
                            let shellStyleColor = {}
                            if (shellColour.ImagePath != null) {
                              shellStyleColor = { backgroundImage: "url('" + shellColour.ImagePath + "')", backgroundSize: "contain" }
                            }
                            else if (shellColour.Code == "Z11") {
                              shellStyleColor = { background: "conic-gradient(blue 50%, red 0)" }
                            }
                            else {
                              shellStyleColor = { backgroundColor: "rgb(" + shellColour.Rgb + ")" }
                            }
                            return (
                              <Col key={index} xs={3}>
                                <a
                                  onClick={() => {
                                    setRightActive(shellColour);
                                  }}
                                >
                                  <div
                                    className={`ite-shell-colour-box ${activeRightITEShellColour && activeRightITEShellColour.Code ===
                                      shellColour.Code
                                      ? "active"
                                      : ""
                                      }`}
                                    style={shellStyleColor}
                                  >
                                    <div className="ite-shell-colour-box-text">
                                      {/* <p>{shellColour.Code}</p> */}
                                      {activeRightITEShellColour && activeRightITEShellColour.Code ===
                                        shellColour.Code ? (
                                        <Done className="icon-shadow" />
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              </Col>
                            )
                          }
                          )}
                        </Row>
                        <Row className="ite-shell-colour-row-right">
                          <Col xs={12}>
                            {iteFaceplateColourRight && iteFaceplateColourRight.length > 0 &&
                              <p>
                                Faceplate Colour:{" "}
                                {activeRightITEFaceplate && (
                                  <strong>
                                    {activeRightITEFaceplate.Name} -{" "}
                                    {activeRightITEFaceplate.Code}
                                  </strong>
                                )}
                              </p>
                            }
                          </Col>
                          {iteFaceplateColourRight && iteFaceplateColourRight.map(
                            (faceplateColour, index) => {
                              let faceplateStyleColour = {};
                              if (faceplateColour.ImagePath != null) {
                                faceplateStyleColour = { backgroundImage: "url('" + faceplateColour.ImagePath + "')", backgroundSize: "contain" }
                              }
                              else {
                                faceplateStyleColour = { backgroundColor: "rgb(" + faceplateColour.Rgb + ")" };
                              }

                              return (
                                <Col key={index} xs={3} className={(selectedShellStyleRight && selectedShellStyleRight.Code == "cShell" && faceplateColour.Name == "Tan") ? "order-last" : ""}>
                                  <a
                                    onClick={() => {
                                      setRightFaceplateActive(faceplateColour);
                                    }}
                                  >
                                    <div
                                      className={`ite-shell-colour-box ${activeRightITEFaceplate && activeRightITEFaceplate.Code ===
                                        faceplateColour.Code
                                        ? "active"
                                        : ""
                                        }`}
                                      style={faceplateStyleColour}
                                    >
                                      <div className="ite-shell-colour-box-text">
                                        {/* <p>{faceplateColour.Code}</p> */}
                                        {activeRightITEFaceplate && activeRightITEFaceplate.Code ===
                                          faceplateColour.Code ? (
                                          <Done className="icon-shadow" />
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                    </div>
                                  </a>
                                </Col>
                              )
                            }
                          )}
                        </Row>
                      </div>
                    )}
                  </Col>
                  <Col md={6} className="left-side">
                    {leftSelected && (
                      <div>
                        <Row className="ite-shell-colour-row-left">
                          <Col xs={12}>
                            {iteShellColourLeft && iteShellColourLeft.length > 0 &&
                              <p>
                                Shell Colour:{" "}
                                {activeLeftITEShellColour && (
                                  <strong>
                                    {activeLeftITEShellColour.Name} -{" "}
                                    {activeLeftITEShellColour.Code}
                                  </strong>
                                )}
                              </p>
                            }
                          </Col>
                          {iteShellColourLeft && iteShellColourLeft.map((shellColour, index) => {
                            let shellStyleColor = {}
                            if (shellColour.ImagePath != null) {
                              shellStyleColor = { backgroundImage: "url('" + shellColour.ImagePath + "')", backgroundSize: "contain" }
                            }
                            else if (shellColour.Code == "Z11") {
                              shellStyleColor = { background: "conic-gradient(blue 50%, red 0)" }
                            }
                            else {
                              shellStyleColor = { backgroundColor: "rgb(" + shellColour.Rgb + ")" }
                            }
                            return (
                              <Col key={index} xs={3}>
                                <a
                                  onClick={() => {
                                    setLeftActive(shellColour);
                                  }}
                                >
                                  <div
                                    className={`ite-shell-colour-box ${activeLeftITEShellColour && activeLeftITEShellColour.Code ===
                                      shellColour.Code
                                      ? "active"
                                      : ""
                                      }`}
                                    style={shellStyleColor}
                                  >
                                    <div className="ite-shell-colour-box-text">
                                      {/* <p>{shellColour.Code}</p> */}
                                      {activeLeftITEShellColour && activeLeftITEShellColour.Code ===
                                        shellColour.Code ? (
                                        <Done className="icon-shadow" />
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              </Col>
                            )
                          }
                          )}
                        </Row>
                        <Row className="ite-shell-colour-row-left">
                          <Col xs={12}>
                            {iteFaceplateColourLeft && iteFaceplateColourLeft.length > 0 &&
                              <p>
                                Faceplate Colour:{" "}
                                {activeLeftITEFaceplate && (
                                  <strong>
                                    {activeLeftITEFaceplate.Name} -{" "}
                                    {activeLeftITEFaceplate.Code}
                                  </strong>
                                )}
                              </p>
                            }
                          </Col>
                          {iteFaceplateColourLeft && iteFaceplateColourLeft.map(
                            (faceplateColour, index) => {
                              let faceplateStyleColour = {};
                              if (faceplateColour.ImagePath != null) {
                                faceplateStyleColour = { backgroundImage: "url('" + faceplateColour.ImagePath + "')", backgroundSize: "contain" }
                              }
                              else {
                                faceplateStyleColour = { backgroundColor: "rgb(" + faceplateColour.Rgb + ")" };
                              }
                              return (
                                <Col key={index} xs={3} className={(selectedShellStyleLeft && selectedShellStyleLeft.Code == "cShell" && faceplateColour.Name == "Tan") ? "order-last" : ""}>
                                  <a
                                    onClick={() => {
                                      setLeftFaceplateActive(faceplateColour);
                                    }}
                                  >
                                    <div
                                      className={`
                                  ite-shell-colour-box
                                  ${activeLeftITEFaceplate && activeLeftITEFaceplate.Code ===
                                          faceplateColour.Code
                                          ? "active"
                                          : ""
                                        } 
                            `}

                                      style={faceplateStyleColour}
                                    >
                                      <div className="ite-shell-colour-box-text">
                                        {/* <p>{faceplateColour.Code}</p> */}
                                        {activeLeftITEFaceplate && activeLeftITEFaceplate.Code ===
                                          faceplateColour.Code ? (
                                          <Done className="icon-shadow" />
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                    </div>
                                  </a>
                                </Col>
                              )
                            }
                          )}
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};

export default ITEShellColour;
