export const CategoriesStatus = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
})

export const OrderStatus = Object.freeze({
  STOCK_ORDER: 'STOCK_ORDER',
  REGULAR: 'REGULAR',
  NONE: 'NONE',
})