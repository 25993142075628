import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { forEach, get } from "lodash";
import { ColourConstants } from "../../store/constants";
import Done from "@material-ui/icons/Done";

import(/* webpackChunkName: "order-builder-colour" */ "./colour.scss");

const Colour = ({ setColourForOrder, colourCodes }) => {
  const [activeColour, setActiveColour] = useState();

  // Set local state and state of order bulder on change
  const setActive = (colourCode) => {
    setActiveColour(colourCode);
    setColourForOrder(colourCode);
  };

  // Sets default value active colour
  useEffect(() => {
    setActiveColour();
    setColourForOrder();
  }, [colourCodes]);

  return (
    <div>
      {colourCodes && (
        <div className="colour-component-main-div order-builder-component">
          <h2 className="order-builder-component-title">Choose a colour</h2>
          <Row className="justify-content-around">
            {colourCodes.map((colour, index) => {
              let colourStyle = {};
              if (colour.ImagePath != null) {
                colourStyle = { backgroundImage: "url('" + colour.ImagePath + "')", backgroundSize: "contain" }
              }
              else {
                colourStyle = { backgroundColor: "rgb(" + colour.Rgb + ")" };
              }
              return (
                <Col key={index} xs={3} md={1}>
                  <a
                    onClick={() => {
                      setActive(colour.Code);
                    }}
                  >
                    <div
                      className={`colour-box ${activeColour === colour.Code ? "active" : ""
                        }`}
                      style={colourStyle}
                    >
                      {activeColour && activeColour ===
                        colour.Code ? (
                        <Done className="selected-colour" />
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="colour-box-text">
                      <p>{colour.Name}</p>
                    </div> 
                  </a>
                </Col>
              )
            }
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default Colour;
