import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import logoPhonak from "../../assets/img/logo-phonak.svg";
import clientLogo from "../../assets/img/xs-specsavers-logo-opt.svg";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NavbarLinks from "../NavbarLinks/NavbarLinks";
import(/* webpackChunkName: "nav-bar" */ "./navbar.scss");

export default function PTopNavbar() {
  let history = useHistory();
  const [ profileData, setProfileData] = useState();
  const [ showSiteError, setShowSiteError] = useState(true);

  useEffect(() => {
    let profileDataTemp = localStorage.getItem("profileData");
    profileDataTemp = JSON.parse(profileDataTemp);
    setProfileData(profileDataTemp);

    if(profileDataTemp && profileDataTemp.Sites && profileDataTemp.Sites.length > 0) {
      //console.log("profileDataTemp - ", profileDataTemp)
      setShowSiteError(false);
    }
  }, []);

  useEffect(() => {
    if(profileData && profileData.Sites && profileData.Sites.length > 0) {
      //console.log("profileData - ", profileData)
      setShowSiteError(false);
    }
  }, [profileData]);

  const logout = () => {
    const cookies = new Cookies();
    //console.log("logout");
    const loginToken = cookies.get("LoginToken");
    if (loginToken) {
      //console.log("logout loginToken", loginToken);
      cookies.remove("LoginToken");
      localStorage.clear();
      history.push({ pathname: "/login" });
    }
  };

  return (
    <div className="top-navbar notprintable">
      <Container>
        <Row>
          <Col xs={2}>
            <div className="navbar-logo">
              <Link className="navbar-brand" to="/">
                <img src={logoPhonak} className="img-fluid" />
              </Link>
            </div>
          </Col>
          <Col md={4}></Col>
          <Col xs={6}>
            <div>
              <div className="float-right">
                <p className="float-right navbar-phone-link">
                  Contact Phonak NZ <a href="tel:+6494861849">+64 9 486 1849</a>
                </p>
                <Nav className="me-auto float-right top-navbar-links">
                  <Link className="nav-link" to="/profile">
                    <PermIdentityIcon />
                  </Link>
                  <Link className="nav-link" to="/cart">
                    <ShoppingCartIcon />
                  </Link>
                  <Nav.Link onClick={logout}>Logout</Nav.Link>
                </Nav>
              </div>
              {profileData && profileData.OrganisationName == "SpecSavers" && 
                <div className="float-right client-logo">
                  <img src={clientLogo} />
                </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bottom-navbar">
        <NavbarLinks />
      </div>
      {showSiteError &&
        <div className="alert-danger py-3">
          <Container>
            <Row>
              <p className="my-0">Your account isn't linked to a Site. Please link to a Site to proceed with orders.</p>
            </Row>
          </Container>
        </div>
      }
    </div>
  );
}
