import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { CategoriesStatus } from './constants';

const categoriesStatus = createReducer({
  [actions.SET_CATEGORIES_STATUS]: (state, { payload }) => payload
}, CategoriesStatus.IDLE);

const categoriesData = createReducer({
  [actions.SET_CATEGORIES_DATA]: (state, { payload }) => payload
}, CategoriesStatus.IDLE);

const orderStatus = createReducer({
  [actions.SET_ORDER_STATUS]: (state, { payload }) => payload
}, CategoriesStatus.IDLE);

export default combineReducers({
  categoriesStatus,
  categoriesData,
  orderStatus
});
