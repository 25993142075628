import React, { useEffect, useState } from "react"; // Importing necessary hooks and modules from React
import PropTypes from "prop-types"; // Importing PropTypes for type-checking
import Loading from "../../components/Loading/Loading"; // Importing a loading component
import { useParams, useHistory, useLocation } from "react-router-dom"; // Importing hooks from react-router-dom for routing
import { find, uniqBy, map, filter, get } from "lodash"; // Importing lodash functions for data manipulation
import { ProductSummaryStatus } from "./store/constants"; // Importing constants related to product summary
import { Row, Col, Button, Form } from "react-bootstrap"; // Importing components from react-bootstrap for UI elements
import testModel from "../../assets/img/ModelTest.png"; // Importing an image asset
import { CategoryContstants } from "../../utilities/categoryConstants"; // Importing category constants
import(/* webpackChunkName: "productSummary" */ "./productSummary.scss"); // Importing SCSS styles with a chunk name for webpack

// Component definition for ProductSummary
const ProductSummary = ({
  loadProductSummary, // Function to load product summary
  productSummaryStatus, // Status of product summary loading
  productSummaryData, // Data related to the product summary
  addOrderToCart, // Function to add order to cart
  cartData, // Data of items currently in the cart
  orderBuilderData, // Data used to build the order
}) => {
  let history = useHistory(); // Hook to access history for navigation
  const location = useLocation(); // Hook to access current location data
  const { productCode } = useParams(); // Hook to access parameters from the route
  const [productAddedToCart, setProductAddedToCart] = useState(); // State for tracking if product is added to cart
  const [leftItemInCart, setLeftItemInCart] = useState(); // State for left item in cart
  const [rightItemInCart, setRightItemInCart] = useState(); // State for right item in cart
  const [leftCustomOptions, setLeftCustomOptions] = useState(); // State for custom options on left item
  const [rightCustomOptions, setRightCustomOptions] = useState(); // State for custom options on right item
  const [leftCustomOptionsArray, setLeftCustomOptionsArray] = useState([]); // State for array of left custom options
  const [rightCustomOptionsArray, setRightCustomOptionsArray] = useState([]); // State for array of right custom options
  const [uniqueProductCategories, setUniqueProductCategories] = useState(); // State for unique product categories
  const [activeRelatedProductCategory, setActiveRelatedProductCategory] =
    useState(); // State for active related product category
  const [productSummaryDataByCategory, setProductSummaryDataByCategory] =
    useState(); // State for product summary data filtered by category
  const [productsForCart, setProductsForCart] = useState(); // State for products added to cart
  const [specialInstructions, setSpecialInstructions] = useState(""); // State for special instructions

  const [displayModelNameLeft, setDisplayModelNameLeft] = useState(""); // State to display model name for left item
  const [displayModelNameRight, setDisplayModelNameRight] = useState(""); // State to display model name for right item

  // useEffect to initialize productsForCart from location state
  useEffect(() => {
    if (location.state.productsForCart) {
      setProductsForCart(location.state.productsForCart);
    }
  }, [location]);

  // useEffect to update state based on productsForCart data
  useEffect(() => {
    if (productsForCart) {
      let uniqueModelTypes = get(orderBuilderData, "Models", []); // Get unique model types from orderBuilderData
      let uniqueEarpieces = get(orderBuilderData, "Earpieces", []); // Get unique earpieces from orderBuilderData

      // Code to find and set left product details
      const productForCartLeft = [...productsForCart];
      let leftProduct = find(
        productForCartLeft,
        { Side: "L", ItemCode: productCode },
        null
      );
      if (!leftProduct) {
        leftProduct = find(
          productForCartLeft,
          { Side: "L", Code: productCode },
          null
        );
      }
      if (!leftProduct) {
        leftProduct = find(
          productForCartLeft,
          { Side: "L" },
          null
        );
      }
      if (leftProduct && leftProduct.ModelTypeCode) {
        let modelTypeObject = find(uniqueModelTypes, { "Code": leftProduct.ModelTypeCode });
        if (modelTypeObject) {
          let modelName = modelTypeObject.Name ? modelTypeObject.Name : leftProduct.ModelTypeCode;
          setDisplayModelNameLeft(modelName);
        }
        else {
          let earpieceTypeObject = find(uniqueEarpieces, { "ModelTypeCode": leftProduct.ModelTypeCode });
          if (earpieceTypeObject) {
            let modelName = earpieceTypeObject.Name ? earpieceTypeObject.Name : leftProduct.ModelTypeCode;
            setDisplayModelNameLeft(modelName);
          }
        }
      }
      setLeftItemInCart(leftProduct);
      const customOptionsLeft = get(leftProduct, "CustomOptions", {});
      setLeftCustomOptions(customOptionsLeft);

      // Code to find and set right product details
      const productForCartRight = [...productsForCart];
      let rightProduct = find(
        productForCartRight,
        { Side: "R", ItemCode: productCode },
        null
      );
      if (!rightProduct) {
        rightProduct = find(
          productForCartRight,
          { Side: "R", Code: productCode },
          null
        );
      }
      if (!rightProduct) {
        rightProduct = find(
          productForCartRight,
          { Side: "R" },
          null
        );
      }
      if (rightProduct && rightProduct.ModelTypeCode) {
        let modelTypeObject = find(uniqueModelTypes, { "Code": rightProduct.ModelTypeCode });
        if (modelTypeObject) {
          let modelName = modelTypeObject.Name ? modelTypeObject.Name : rightProduct.ModelTypeCode;
          setDisplayModelNameRight(modelName);
        }
        else {
          let earpieceTypeObject = find(uniqueEarpieces, { "ModelTypeCode": rightProduct.ModelTypeCode });
          if (earpieceTypeObject) {
            let modelName = earpieceTypeObject.Name ? earpieceTypeObject.Name : rightProduct.ModelTypeCode;
            setDisplayModelNameRight(modelName);
          }
        }
      }
      setRightItemInCart(rightProduct);
      const customOptionsRight = get(rightProduct, "CustomOptions", {});
      setRightCustomOptions(customOptionsRight);
    }
  }, [productsForCart]);

  // useEffect to update arrays for custom options based on current options data
  useEffect(() => {
    if (rightCustomOptions) {
      const tempArray = Object.keys(rightCustomOptions);
      setRightCustomOptionsArray(tempArray);
    }
    if (leftCustomOptions) {
      const tempArray = Object.keys(leftCustomOptions);
      setLeftCustomOptionsArray(tempArray);
    }
  }, [rightCustomOptions, leftCustomOptions]);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    const { specialInstructions } = document.forms[0]; // Retrieve special instructions from form

    let currentCart = [];
    currentCart = [...cartData];
    
    // Updating existing cart items with special instructions
    currentCart = cartData.map((obj) => {
      if (obj && obj.Code === productCode) {
        obj.specialInstructions = specialInstructions.value;
        return obj;
      }
      return obj;
    });

    addOrderToCart(currentCart); // Add updated cart to the state

    // Navigate to cart or related products page based on item in cart
    if (
      (rightItemInCart && rightItemInCart.CategoryCode === "Earpieces") ||
      (leftItemInCart && leftItemInCart.CategoryCode === "Earpieces")
    ) {
      history.push({
        pathname: `/cart/`,
      });
    }

    if (rightItemInCart) {
      history.push({
        pathname: `/related-products/${rightItemInCart.ItemCode}`,
        state: { productsAddedToCart: productsForCart },
      });
    }
    if (leftItemInCart) {
      history.push({
        pathname: `/related-products/${leftItemInCart.ItemCode}`,
        state: { productsAddedToCart: productsForCart },
      });
    }
  };

  return (
    <div className="product-summary-main-div">
      {(rightItemInCart || leftItemInCart) && (
        <Form onSubmit={handleSubmit}>
          <div>
            <Row>
              <Col xs={12}>
                <h1>
                  Product Summary
                  <Button type="submit" className="go-to-cart-button">
                    Add to Cart
                  </Button>
                </h1>
              </Col>
            </Row>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Right Side</th>
                  <th>Left Side</th>
                </tr>
              </thead>
              <tbody>
              {/* Mapping over various items to create rows in the table */}
              {(rightItemInCart && (rightItemInCart.ModelTypeCode || rightItemInCart.ModelTypeName) ||
                (leftItemInCart && (leftItemInCart.ModelTypeCode || leftItemInCart.ModelTypeName))) && (
              
				          <tr>
                    <td>Instrument Model</td>
                    <td>
                      {displayModelNameRight ? displayModelNameRight : (rightItemInCart
                        ? (rightItemInCart.ModelTypeName ? 
                          rightItemInCart.ModelTypeName : (rightItemInCart.ModelTypeCode ? rightItemInCart.ModelTypeCode : "")) : "")}
                    </td>
                    <td>
                      {displayModelNameLeft ? displayModelNameLeft : (leftItemInCart
                        ? (leftItemInCart.ModelTypeName ?
                          leftItemInCart.ModelTypeName : (leftItemInCart.ModelTypeCode ? leftItemInCart.ModelTypeCode : "")) : "")}
                    </td>
                  </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.ShellStyle) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.ShellStyle)) && (
                    
                    <tr>
                      <td>Shell Style</td>
                      <td>
                        {rightItemInCart ? (rightItemInCart.ConfigurationGroup.ShellStyle.Name ? rightItemInCart.ConfigurationGroup.ShellStyle.Name : rightItemInCart.ConfigurationGroup.ShellStyle) : ""}
                      </td>
                      <td>
                        {leftItemInCart ? (leftItemInCart.ConfigurationGroup.ShellStyle.Name ? leftItemInCart.ConfigurationGroup.ShellStyle.Name : leftItemInCart.ConfigurationGroup.ShellStyle) : ""}
                      </td>
                    </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.PowerLevel &&
                rightItemInCart.ConfigurationGroup.PowerLevel.Name) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.PowerLevel &&
                  leftItemInCart.ConfigurationGroup.PowerLevel.Name)) && (
                  <tr>
                    <td>Power Level</td>
                    <td>{rightItemInCart
                      ? rightItemInCart.ConfigurationGroup.PowerLevel.Name
                      : ""}</td>
                    <td>{leftItemInCart
                      ? leftItemInCart.ConfigurationGroup.PowerLevel.Name
                      : ""}</td>
                  </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.ShellColour &&
                rightItemInCart.ConfigurationGroup.ShellColour.Name) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.ShellColour &&
                  leftItemInCart.ConfigurationGroup.ShellColour.Name)) && (
                  <tr>
                    <td>Shell Colour</td>
                    <td>{rightItemInCart
                      ? rightItemInCart.ConfigurationGroup.ShellColour
                        .Name
                      : ""}</td>
                    <td>{leftItemInCart
                      ? leftItemInCart.ConfigurationGroup.ShellColour.Name
                      : ""}</td>
                  </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.FaceplateColour &&
                rightItemInCart.ConfigurationGroup.FaceplateColour.Name) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.FaceplateColour &&
                  leftItemInCart.ConfigurationGroup.FaceplateColour.Name)) && (
                  <tr>
                    <td>Faceplate Colour</td>
                    <td>{rightItemInCart
                      ? rightItemInCart.ConfigurationGroup.FaceplateColour
                        .Name
                      : ""}</td>
                    <td> {leftItemInCart
                      ? leftItemInCart.ConfigurationGroup.FaceplateColour
                        .Name
                      : ""}</td>
                  </tr>
              )}
              
            {((rightItemInCart &&
              rightItemInCart.ConfigurationGroup &&
              rightItemInCart.ConfigurationGroup.PowerLevel &&
              rightItemInCart.ConfigurationGroup.PowerLevel.Name) ||
              (leftItemInCart &&
                leftItemInCart.ConfigurationGroup &&
                leftItemInCart.ConfigurationGroup.PowerLevel &&
                leftItemInCart.ConfigurationGroup.PowerLevel.Name)) && (
                  <tr>
                <td>Power Level</td>
                <td>{rightItemInCart
                            ? rightItemInCart.ConfigurationGroup.PowerLevel.Name
                            : ""}</td>
                <td>{leftItemInCart
                            ? leftItemInCart.ConfigurationGroup.PowerLevel.Name
                            : ""}</td>
              </tr>
            )}
            {((rightItemInCart &&
              rightItemInCart.ConfigurationGroup &&
              (rightItemInCart.ConfigurationGroup.ShellColour || 
              rightItemInCart.ConfigurationGroup.ShellColourName)) ||
              (leftItemInCart &&
                leftItemInCart.ConfigurationGroup &&
                (leftItemInCart.ConfigurationGroup.ShellColour ||
                leftItemInCart.ConfigurationGroup.ShellColourName))) && (
                      <tr>
                <td>Shell Colour</td>
                <td>{rightItemInCart
                            ? (rightItemInCart.ConfigurationGroup.ShellColourName ? rightItemInCart.ConfigurationGroup.ShellColourName : rightItemInCart.ConfigurationGroup.ShellColour)
                            : ""}</td>
                <td>{leftItemInCart
                            ? (leftItemInCart.ConfigurationGroup.ShellColourName ? leftItemInCart.ConfigurationGroup.ShellColourName : leftItemInCart.ConfigurationGroup.ShellColour)
                            : ""}</td>
              </tr>
            )}
            {((rightItemInCart &&
              rightItemInCart.ConfigurationGroup &&
              (rightItemInCart.ConfigurationGroup.FaceplateColour ||
                rightItemInCart.ConfigurationGroup.FaceplateColourName)) ||
              (leftItemInCart &&
                leftItemInCart.ConfigurationGroup &&
                (leftItemInCart.ConfigurationGroup.FaceplateColour ||
                leftItemInCart.ConfigurationGroup.FaceplateColourName))) && (
                  <tr>
                  <td>Faceplate Colour</td>
                  <td>
                    {rightItemInCart
                      ? (rightItemInCart.ConfigurationGroup.FaceplateColourName ? rightItemInCart.ConfigurationGroup.FaceplateColourName : rightItemInCart.ConfigurationGroup.FaceplateColour)
                      : ""}
                  </td>
                  <td>
                    {leftItemInCart
                      ? (leftItemInCart.ConfigurationGroup.FaceplateColourName ? leftItemInCart.ConfigurationGroup.FaceplateColourName : leftItemInCart.ConfigurationGroup.FaceplateColour)
                      : ""}
                  </td>
                </tr>
            )}
                
                {/* Custom options rendering */}
                {rightCustomOptionsArray.length > 0 &&
                  leftCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td>{rightCustomOptions[customOption]}</td>
                        <td>{leftCustomOptions[customOption]}</td>
                      </tr>
                    );
                  })}
                {/* Render only right custom options */}
                {leftCustomOptionsArray.length <= 0 &&
                  rightCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td>{rightCustomOptions[`${customOption}`]}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                {/* Render only left custom options */}
                {leftCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.length <= 0 &&
                  leftCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td></td>
                        <td>{leftCustomOptions[`${customOption}`]}</td>
                      </tr>
                    );
                  })}
                {/* Ear impression scan information */}
                {((rightItemInCart && rightItemInCart.EarImpression) ||
                  (leftItemInCart && leftItemInCart.EarImpression)) && (
                  <tr>
                    <td>Ear Impression Scan</td>
                    <td>
                      {rightItemInCart
                        ? rightItemInCart.EarImpression
                        : ""}
                    </td>
                    <td>
                      {leftItemInCart
                        ? leftItemInCart.EarImpression
                        : ""}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Row>
            <Col xs={12} className="special-instructions-div">
              <p>Special instructions for this item</p>
              <textarea
                id="specialInstructions"
                name="specialInstructions"
                rows="8"
              ></textarea>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

// Defining PropTypes for ProductSummary component
ProductSummary.propTypes = {
  cartData: PropTypes.array, // Array of cart data
  orderBuilderData: PropTypes.object, // Object for order builder data
  addOrderToCart: PropTypes.func, // Function to add order to cart
};

// Exporting the ProductSummary component
export default ProductSummary;