import { createSelector } from 'reselect';

const categories = state => state.modules.storeHome;

export const getCategoriesStatus = createSelector(
  categories,
  state => state.categoriesStatus
);

export const getCategoriesData = createSelector(
  categories,
  state => state.categoriesData
);

export const getOrderStatus = createSelector(
  categories,
  state => state.orderStatus
);
