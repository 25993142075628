// actions.js

// Action Type Constants
export const SET_ORDER_CONFIRMATION_STATUS = 'repairCheckout/SET_ORDER_CONFIRMATION_STATUS';
export const SET_ORDER_CONFIRMATION_DATA = 'repairCheckout/SET_ORDER_CONFIRMATION_DATA';
export const SET_SENT_ORDER_CONFIRMATION_STATUS = 'repairCheckout/SET_SENT_ORDER_CONFIRMATION_STATUS';
export const SET_ORDER_NUMBER = 'repairCheckout/SET_ORDER_NUMBER';
export const SUBMIT_REPAIR_ORDER = 'repairCheckout/SUBMIT_REPAIR_ORDER';

// Action Creators
export const setOrderConfirmationStatus = (payload) => ({
  type: SET_ORDER_CONFIRMATION_STATUS,
  payload,
});

export const setOrderConfirmationData = (payload) => ({
  type: SET_ORDER_CONFIRMATION_DATA,
  payload,
});

export const setSentOrderConfirmationStatus = (payload) => ({
  type: SET_SENT_ORDER_CONFIRMATION_STATUS,
  payload,
});

export const setOrderNumber = (payload) => ({
  type: SET_ORDER_NUMBER,
  payload,
});

export const submitRepairOrder = (payload) => ({
  type: SUBMIT_REPAIR_ORDER,
  payload,
});
