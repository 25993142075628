import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { uniqBy, map, filter, concat, merge, get, forEach, find } from "lodash";
import { Row, Col } from "react-bootstrap";

//Components
import Loading from "../../../../components/Loading/Loading";
import SelectSides from "../SelectSides/SelectSides";
import Graph from "../Graph/Graph";
import EarImpression from "../EarImpression/EarImpression";
import ITEPerformanceLevel from "../ITEPerformanceLevel/ITEPerformanceLevel";
import ITEModel from "../ITEModel/ITEModel";
import ITEShellStyle from "../ITEShellStyle/ITEShellStyle";
import ITEPowerLevel from "../ITEPowerLevel/ITEPowerLevel";
import ITEShellColour from "../ITEShellColour/ITEShellColour";
import ITECustomOptions from "../ITECustomOptions/ITECustomOptions";

//Constants
import { CartStatus, OrderBuilderStatus } from "../../store/constants";
import { OrderStatus } from "../../../storeHome/store/constants";

import(/* webpackChunkName: "order-builder-ite" */ "./iteItemBuilder.scss");

const ITEItemBuilder = ({
  cartStatus,
  addOrderToCart,
  orderBuilderStatus,
  orderBuilderData,
  cartData,
  configurationGroupStatus,
  configurationGroupData,
  configurationGroupLeft,
  configurationGroupRight,
  loadConfigurationGroup,
  customOptionsGroupStatus,
  customOptionsGroupData,
  customOptionsGroupLeft,
  customOptionsGroupRight,
  loadCustomOptionsGroup,
  setOrderStatus,
}) => {
  let history = useHistory();
  const { productId } = useParams();
  const [selectedModel, setSelectedModel] = useState();
  const [leftSelected, setLeftSelected] = useState(false);
  const [rightSelected, setRightSelectedSides] = useState(false);

  const [earImpressionForOrder, setEarImpressionForOrder] = useState(false);

  const [itePerformanceLevelForOrder, setITEPerformanceLevelForOrder] =
    useState();
  const [itePerformanceLevelCodes, setITEPerformanceLevel] = useState([]);

  const [iteModelForOrder, setITEModelForOrder] = useState();
  const [iteModelTypeCodes, setITEModelTypeCodes] = useState();
  const [iteModelLinked, setITEModelLinked] = useState(true);

  const [iteShellStyleLeft, setITEShellStyleLeft] = useState();
  const [iteShellStyleRight, setITEShellStyleRight] = useState();
  const [selectedShellStyleRight, setSelectedShellStyleRight] = useState("");
  const [selectedShellStyleLeft, setSelectedShellStyleLeft] = useState("");

  const [itePowerLevelRight, setITEPowerLevelRight] = useState();
  const [itePowerLevelLeft, setITEPowerLevelLeft] = useState();
  const [activeRightITEPowerLevel, setActiveRightITEPowerLevel] = useState({
    Code: "",
  });
  const [activeLeftITEPowerLevel, setActiveLeftITEPowerLevel] = useState({
    Code: "",
  });

  const [iteShellColourLeft, setITEShellColourLeft] = useState();
  const [iteShellColourRight, setITEShellColourRight] = useState();
  const [activeRightITEShellColour, setActiveRightITEShellColour] = useState();
  const [activeLeftITEShellColour, setActiveLeftITEShellColour] = useState();

  const [iteFaceplateColourLeft, setITEFaceplateColourLeft] = useState();
  const [iteFaceplateColourRight, setITEFaceplateColourRight] = useState();
  const [activeRightITEFaceplate, setActiveRightITEFaceplate] = useState("");
  const [activeLeftITEFaceplate, setActiveLeftITEFaceplate] = useState();

  const [iteCustomOptionsForOrder, setITECustomOptionsForOrder] = useState({
    right: [],
    left: [],
  });

  const [iteConfigurationGroupLeft, setITEConfigurationGroupLeft] = useState(
    {}
  );
  const [iteConfigurationGroupRight, setITEConfigurationGroupRight] = useState(
    {}
  );

  const [errorMessages, setErrorMessages] = useState([]);
  const [errorMessageRight, setErrorMessageRight] = useState("");
  const [errorMessageLeft, setErrorMessageLeft] = useState("");
  const [rightAudiogram, setRightAudiogram] = useState(
    Array.apply(null, Array(13))
  );
  const [leftAudiogram, setLeftAudiogram] = useState(
    Array.apply(null, Array(13))
  );

  const [ manualVent, setManualVent ] = useState(false);

  const [currentCartForOrderSummary, setCurrentCartForOrderSummary] =
    useState();

  const [itemName, setItemName] = useState();
  const [pageDescription, setPageDescription] = useState();

  const [selectedModelRight, setSelectedModelRight] = useState("");
  const [selectedModelLeft, setSelectedModelLeft] = useState("");

  //THIS PAGE FUNCTIONS VERY SIMILAR TO EAR PIECES PAGE
  useEffect(() => {
    //If product was successfully added to cart move user to related-products page.
    if (
      cartStatus === CartStatus.SUCCESS &&
      cartData &&
      iteModelForOrder &&
      (iteModelForOrder.right || iteModelForOrder.left)
    ) {
      let tempOrderBuilderData = get(orderBuilderData, "Items", []);
      let orderItemsArray = [...tempOrderBuilderData];

      if (iteModelForOrder.right) {
        let orderItemRight = find(orderItemsArray, {
          PerformanceLevelCode: itePerformanceLevelForOrder,
          ModelTypeCode: iteModelForOrder.right,
        });
        history.push({
          pathname: `/product-summary/${orderItemRight.Code}`,
          state: { productsForCart: currentCartForOrderSummary },
        });
      }
      if (iteModelForOrder.left) {
        let orderItemLeft = find(orderItemsArray, {
          PerformanceLevelCode: itePerformanceLevelForOrder,
          ModelTypeCode: iteModelForOrder.left,
        });
        history.push({
          pathname: `/product-summary/${orderItemLeft.Code}`,
          state: { productsForCart: currentCartForOrderSummary },
        });
      }
    }
  }, [cartStatus]);

  useEffect(() => {
    //Reset options effected by model type when switching product category
    if (iteModelForOrder) {
      if (!iteModelForOrder.right || !iteModelForOrder.left) {
        setITEConfigurationGroupLeft({});
        setITEConfigurationGroupRight({});
      }
    }
  }, [iteModelForOrder]);

  useEffect(() => {
    //Set configurationGroupLeft and configurationGroupRight options
    setITEConfigurationGroupLeft(configurationGroupLeft);
    setITEConfigurationGroupRight(configurationGroupRight);
  }, [configurationGroupLeft, configurationGroupRight]);

  const checkAudiogram = (arr, index) => {
    if (index >= 0 && index < arr.length && arr[index] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  const startAddOrderToCart = () => {
    setErrorMessages([]);
    var tempErrorMessages = [];

    setErrorMessageRight("");
    setErrorMessageLeft("");
    if (!rightSelected && !leftSelected) {
      tempErrorMessages.push("Select at least one side.");
    }
    if (rightSelected && !manualVent) {
      if (
        ![2, 4, 6, 8, 10].every(index => checkAudiogram(rightAudiogram, index))
      ) {
        setErrorMessageRight("Missing data points");
        tempErrorMessages.push(
          "The right audiogram is missing some required data"
        );
      }
    }
    if (leftSelected && !manualVent) {
      if (
        ![2, 4, 6, 8, 10].every(index => checkAudiogram(leftAudiogram, index))
      ) {
        setErrorMessageLeft("Missing data points");
        tempErrorMessages.push(
          "The left audiogram is missing some required data"
        );
      }
    }
    if(!itePerformanceLevelForOrder) {
      tempErrorMessages.push("Please select a performance level");
    }
    
    if (earImpressionForOrder.left) {
      if (earImpressionForOrder.left.type === "impressionOnFile") {
        if (!earImpressionForOrder.left.serialNumber) {
          tempErrorMessages.push("You selected to use an impression on file for the left ear but the serial number field is empty. Please enter a serial number.");
        }
      }
    }
    
    if (earImpressionForOrder.right) {
      if (earImpressionForOrder.right.type === "impressionOnFile") {
        if (!earImpressionForOrder.right.serialNumber) {
          tempErrorMessages.push("You selected to use an impression on file for the right ear but the serial number field is empty. Please enter a serial number.");
        }
      }
    }
    
    if (tempErrorMessages.length > 0) {
      setErrorMessages(tempErrorMessages);
      return;
    }

    let order = [];

    let tempOrderBuilderData = get(orderBuilderData, "Items", []);

    //Adding right item to order
    if (rightSelected) {
      let orderItemsArray = [...tempOrderBuilderData];
      //Getting item from item list that matches performance level and model type selected
      let orderItemRight = find(orderItemsArray, {
        PerformanceLevelCode: itePerformanceLevelForOrder,
        ModelTypeCode: iteModelForOrder.right,
      });
      let tempOrderItemRight = { ...orderItemRight };
      //Configuring Custom Options from array of options selected by paring them with the category name.
      let tempArrayOfCustomOptions = get(iteCustomOptionsForOrder, "right", []);
      let finalObjectOfCustomOptions = {};
      tempArrayOfCustomOptions.forEach((option, index) => {
        let optionName = get(customOptionsGroupRight[index], "Name", "N/A");
        finalObjectOfCustomOptions[`${optionName}`] = option;
      });

      let EarImpression = "";
      if (earImpressionForOrder.right) {
        if (earImpressionForOrder.right.type === "impressionOnFile") {
          EarImpression = earImpressionForOrder.right.serialNumber;
        } else if (earImpressionForOrder.right.type === "mailImpression") {
          EarImpression = "Mail in an impression";
        } else if (earImpressionForOrder.right.type === "scanAttached") {
          EarImpression = "Use the scan attached";
        }
      }

      let tempShellStyle = "";
      if(selectedShellStyleRight)
      {
        tempShellStyle = selectedShellStyleRight 
        if(selectedShellStyleRight.Code){
          tempShellStyle = selectedShellStyleRight.Code;
        }
      }

      // //Configuring Configuration Group from selected items.
      let tempConfigurationGroup = {
        ShellStyle: tempShellStyle,
        ShellStyleName: get(selectedShellStyleRight, "Name", null),
        PowerLevel: get(activeRightITEPowerLevel, "Code", null),
        ShellColour: get(activeRightITEShellColour, "Code", null),
        FaceplateColour: get(activeRightITEFaceplate, "Code", null),
        ShellColourName: get(activeRightITEShellColour, "Name", null),
        FaceplateColourName: get(activeRightITEFaceplate, "Name", null),
      };

      let rightAudiogramObject = {
        125: rightAudiogram[0],
        250: rightAudiogram[2],
        500: rightAudiogram[4],
        750: rightAudiogram[5],
        "1K": rightAudiogram[6],
        "1.5K": rightAudiogram[7],
        "2K": rightAudiogram[8],
        "3K": rightAudiogram[9],
        "4K": rightAudiogram[10],
        "6K": rightAudiogram[11],
        "8K": rightAudiogram[12],
      };

      var addValuesForOrderRight = {
        Quantity: 1,
        ItemCode: tempOrderItemRight.Code,
        Side: "R",
        ConfigurationGroup: tempConfigurationGroup,
        CustomOptions: finalObjectOfCustomOptions,
        EarImpression: EarImpression,
        Audiogram: rightAudiogramObject,
        manualVent: manualVent,
        Type: "ITE",
      };

      merge(tempOrderItemRight, addValuesForOrderRight);
      order.push(tempOrderItemRight);
    }

    //Adding left item to order
    if (leftSelected) {
      let orderItemsArray = [...tempOrderBuilderData];
      //Getting item from item list that matches performance level and model type selected
      let orderItemLeft = find(orderItemsArray, {
        PerformanceLevelCode: itePerformanceLevelForOrder,
        ModelTypeCode: iteModelForOrder.left,
      });
      let tempOrderItemLeft = { ...orderItemLeft };

      let tempShellStyle = "";
      if(selectedShellStyleLeft)
      {
        tempShellStyle = selectedShellStyleLeft 
        if(selectedShellStyleLeft.Code){
          tempShellStyle = selectedShellStyleLeft.Code;
        }
      }

      //Configuring Configuration Group from selected items.
      let tempConfigurationGroup = {
        ShellStyle: tempShellStyle,
        ShellStyleName: get(selectedShellStyleLeft, "Name", null),
        PowerLevel: get(activeLeftITEPowerLevel, "Code", null),
        ShellColour: get(activeLeftITEShellColour, "Code", null),
        FaceplateColour: get(activeLeftITEFaceplate, "Code", null),
        ShellColourName: get(activeLeftITEShellColour, "Name", null),
        FaceplateColourName: get(activeLeftITEFaceplate, "Name", null),
      };

      //Set earimpression on file
      // //console.log("earImpressionForOrder", earImpressionForOrder);
      let EarImpression = "";
      if (earImpressionForOrder.left) {
        if (earImpressionForOrder.left.type === "impressionOnFile") {
          EarImpression = earImpressionForOrder.left.serialNumber;
        } else if (earImpressionForOrder.left.type === "mailImpression") {
          EarImpression = "Mail in an impression";
        } else if (earImpressionForOrder.left.type === "scanAttached") {
          EarImpression = "Use the scan attached";
        }
      }

      //Set audiogram for item
      //Configuring Custom Options from array of options selected by paring them with the category name.
      let tempArrayOfCustomOptions = get(iteCustomOptionsForOrder, "left", []);
      let finalObjectOfCustomOptions = {};
      tempArrayOfCustomOptions.forEach((option, index) => {
        let optionName = get(customOptionsGroupLeft[index], "Name", "N/A");
        finalObjectOfCustomOptions[`${optionName}`] = option;
      });

      let leftAudiogramObject = {
        125: leftAudiogram[0],
        250: leftAudiogram[2],
        500: leftAudiogram[4],
        750: leftAudiogram[5],
        "1K": leftAudiogram[6],
        "1.5K": leftAudiogram[7],
        "2K": leftAudiogram[8],
        "3K": leftAudiogram[9],
        "4K": leftAudiogram[10],
        "6K": leftAudiogram[11],
        "8K": leftAudiogram[12],
      };

      var addValuesForOrderLeft = {
        Quantity: 1,
        ItemCode: tempOrderItemLeft.Code,
        Side: "L",
        ConfigurationGroup: tempConfigurationGroup,
        CustomOptions: finalObjectOfCustomOptions,
        EarImpression: EarImpression,
        Audiogram: leftAudiogramObject,
        manualVent: manualVent,
        Type: "ITE",
      };
      merge(tempOrderItemLeft, addValuesForOrderLeft);
      order.push(tempOrderItemLeft);
    }
    //console.log("order", order);
    //Checking current cart to see if items are already in the cart
    let currentCart = [];
    if (cartData) {
      //console.log("cartData", cartData);

      currentCart = [...cartData];
      //Either adding order to cart if it's not already in the cart.
      //Or increasing quantity by 1 if the same item is already in the cart.
      // Note: Discovered cart can have null item in array - updated below to check that object exists
      order.forEach((orderItem) => {
        //console.log("orderItem 1", orderItem);

        if (
          currentCart.some(
            (e) => e && e.Code === orderItem.Code && e.Side === orderItem.Side
          )
        ) {
          currentCart = cartData.map((obj) => {
            if (obj && obj.Code === orderItem.Code && obj.Side === orderItem.Side) {
              obj.Quantity = obj.Quantity + 1;
              //console.log("orderItem 2", orderItem);
              return obj;
            }
            //console.log("orderItem 3", orderItem);
            return obj;
          });
        } else {
          //console.log("orderItem 4", orderItem);
          currentCart.push(orderItem);
        }
      });
    } else {
      currentCart = order;
    }
    setCurrentCartForOrderSummary(order);
    //console.log("currentCart", currentCart);
    setOrderStatus(OrderStatus.REGULAR);
    addOrderToCart(currentCart);
  };

  //SET UNIQUE PERFORMANCE LEVELS
  useEffect(() => {
    if (orderBuilderStatus === OrderBuilderStatus.SUCCESS) {
      const itemList = get(orderBuilderData, "Items", []);
      // let tempItemName = itemList[0];
      // tempItemName = get(tempItemName, "Name", "Item");
      // setItemName(tempItemName);

      const category = get(orderBuilderData, "Category", {});
      if (category) {
        let tempItemName = category.Name;
        setItemName(tempItemName);
        setPageDescription(category.Description);
      }

      //Get uniqueITEPerformanceLevelForOrder from data
      let uniqueITEPerformanceLevelForOrder = [...itemList];
      uniqueITEPerformanceLevelForOrder = filter(
        uniqueITEPerformanceLevelForOrder,
        { CategoryCode: productId }
      );
      uniqueITEPerformanceLevelForOrder = uniqBy(
        uniqueITEPerformanceLevelForOrder,
        "PerformanceLevelCode"
      );
      uniqueITEPerformanceLevelForOrder = map(
        uniqueITEPerformanceLevelForOrder,
        "PerformanceLevelCode"
      );

      let uniqueITEPerformanceLevel = get(
        orderBuilderData,
        "PerformanceLevels",
        []
      );

      let performanceLevelArray = [];
      forEach(uniqueITEPerformanceLevelForOrder, function (performanceLevel) {
        let performanceLevelObject = find(uniqueITEPerformanceLevel, {
          Code: performanceLevel,
        });
        if (performanceLevelObject) {
          performanceLevelArray.push(performanceLevelObject);
        }
      });

      setITEPerformanceLevel(performanceLevelArray);
    }
  }, [orderBuilderStatus]);

  //SET UNIQUE MODELS LEVELS
  useEffect(() => {
    if (itePerformanceLevelForOrder) {
      //Get all items with modelTypeSelected
      const itemList = get(orderBuilderData, "Items", []);
      let orderBuilderDataAfterPerformanceLevel = [...itemList];
      // //console.log("itePerformanceLevelForOrder", itePerformanceLevelForOrder);
      orderBuilderDataAfterPerformanceLevel = filter(
        orderBuilderDataAfterPerformanceLevel,
        { PerformanceLevelCode: itePerformanceLevelForOrder }
      );
      //Get uniquePerformanceLevelForOrder
      let uniqueModelTypeForOrder = orderBuilderDataAfterPerformanceLevel;
      uniqueModelTypeForOrder = uniqBy(
        uniqueModelTypeForOrder,
        "ModelTypeCode"
      );
      uniqueModelTypeForOrder = map(uniqueModelTypeForOrder, "ModelTypeCode");
      //Removes nulls
      uniqueModelTypeForOrder = filter(uniqueModelTypeForOrder, (v) => v);
      // //console.log("uniqueModelTypeForOrder", uniqueModelTypeForOrder);
      let uniqueModels = get(orderBuilderData, "Models", []);
      // //console.log("uniqueModels", uniqueModels);

      let modelArray = [];
      forEach(uniqueModelTypeForOrder, function (model) {
        let modelObject = find(uniqueModels, { Code: model });
        // //console.log("modelObject", modelObject);

        if (modelObject) {
          modelArray.push(modelObject);
        }
      });

      setITEModelTypeCodes(modelArray);
    }
  }, [itePerformanceLevelForOrder]);

  //SET Configuration Groups & Custom Options
  useEffect(() => {
    if (!iteModelForOrder) {
      return;
    }

    let rightModelHasChanged = false;
    let leftModelHasChanged = false;

    if (!iteModelLinked) {
      rightModelHasChanged = selectedModelRight !== iteModelForOrder.right;
      leftModelHasChanged = selectedModelLeft !== iteModelForOrder.left;
    }

    if (rightModelHasChanged) {
      setSelectedModelRight(iteModelForOrder.right);
    }
    if (leftModelHasChanged) {
      setSelectedModelLeft(iteModelForOrder.left);
    }

    if (iteModelLinked) {
      let modelCode = "";
      if (iteModelForOrder.right) {
        modelCode = iteModelForOrder.right;
      }
      const modelObject = find(iteModelTypeCodes, { Code: modelCode }, null);
      if (modelObject) {
        loadConfigurationGroup(
          "B",
          modelObject.ConfigurationGroupCode,
          configurationGroupData,
        );
        loadCustomOptionsGroup(
          "B",
          modelObject.Code,
          customOptionsGroupData,
        );
      }
      
    } else if (iteModelForOrder.right && rightModelHasChanged) {
      const rightModelCode = iteModelForOrder.right;
      const rightModelObject = find(
        iteModelTypeCodes,
        { Code: rightModelCode },
        {}
      );
      // console.log("rightModelObject - ", rightModelObject)
      if (rightModelObject) {
        loadConfigurationGroup(
          "R",
          rightModelObject.ConfigurationGroupCode,
          configurationGroupData,
        );
        loadCustomOptionsGroup(
          "R",
          rightModelObject.Code,
          customOptionsGroupData
        );
      }
    } else if (iteModelForOrder.left && leftModelHasChanged) {
      const leftModelCode = iteModelForOrder.left;
      const leftModelObject = find(
        iteModelTypeCodes,
        { Code: leftModelCode },
        {}
      );
      // console.log("leftModelObject - ", leftModelObject)
      if (leftModelObject) {
        loadConfigurationGroup(
          "L",
          leftModelObject.ConfigurationGroupCode,
          configurationGroupData,
        );
        loadCustomOptionsGroup(
          "L",
          leftModelObject.Code,
          customOptionsGroupData
        );
      }
    }
  }, [iteModelForOrder]);

  //SET Custom options + Config groups
  // useEffect(() => {
  //   // //console.log(iteModelForOrder);
  //   if (iteModelForOrder && (iteModelForOrder.left || iteModelForOrder.right)) {
  //     if (iteModelLinked) {
  //       let modelObject = {};
  //       if (iteModelForOrder.right) {
  //         modelObject = iteModelForOrder.right;
  //       }
  //       if (iteModelForOrder.left) {
  //         modelObject = iteModelForOrder.left;
  //       }
  //       const modelObj = find(iteModelTypeCodes, { Code: modelObject }, {});
  //       console.log("modelObj.ConfigurationGroupCode", modelObj.ConfigurationGroupCode)
  //       console.log("modelObj.Code", modelObj.Code)
  //       if (modelObj) {
  //         loadConfigurationGroup(
  //           "B",
  //           modelObj.ConfigurationGroupCode,
  //           configurationGroupData
  //         );
  //       }
  //       //loadConfigurationGroup("B", "testconfig1", configurationGroupData);
  //       // //console.log("loadCustomOptionsGroup", customOptionsGroupData);
  //       loadCustomOptionsGroup("B", modelObject, customOptionsGroupData);
  //     } else if (!iteModelLinked) {
  //       // console.log("right + Left", rightModelObject.ConfigurationGroupCode, leftModelObject.ConfigurationGroupCode)
  //       if (iteModelForOrder.right) {
  //         const rightModelCode = iteModelForOrder.right;
  //         const rightModelObject = find(
  //           iteModelTypeCodes,
  //           { Code: rightModelCode },
  //           {}
  //         );
  //         if (rightModelObject) {
  //           loadConfigurationGroup(
  //             "R",
  //             rightModelObject.ConfigurationGroupCode,
  //             configurationGroupData
  //           );
  //         }

  //         //loadConfigurationGroup("R", "testconfig1", configurationGroupData);
  //         loadCustomOptionsGroup("R", rightModelCode, customOptionsGroupData);
  //       }
  //       if (iteModelForOrder.left) {
  //         const leftModelCode = iteModelForOrder.left;
  //         const leftModelObject = find(
  //           iteModelTypeCodes,
  //           { Code: leftModelCode },
  //           {}
  //         );
  //         if (leftModelObject) {
  //           loadConfigurationGroup(
  //             "L",
  //             leftModelObject.ConfigurationGroupCode,
  //             configurationGroupData
  //           );
  //         }
  //         //loadConfigurationGroup("L", "testconfig1", configurationGroupData);
  //         loadCustomOptionsGroup("L", leftModelCode, customOptionsGroupData);
  //       }
  //     }
  //   }
  // }, [iteModelForOrder]);

  useEffect(() => {
    if (iteConfigurationGroupLeft) {
      const shellStyleLeft = get(iteConfigurationGroupLeft, "ShellStyles");
      setITEShellStyleLeft(shellStyleLeft);

      const powerLevelLeft = get(iteConfigurationGroupLeft, "PowerLevels");
      setITEPowerLevelLeft(powerLevelLeft);

      const shellColourLeft = get(iteConfigurationGroupLeft, "ShellColours");
      setITEShellColourLeft(shellColourLeft);

      const faceplateColourLeft = get(
        iteConfigurationGroupLeft,
        "FaceplateColours"
      );
      setITEFaceplateColourLeft(faceplateColourLeft);
    }
    if (iteConfigurationGroupRight) {
      const shellStyleRight = get(iteConfigurationGroupRight, "ShellStyles");
      setITEShellStyleRight(shellStyleRight);

      const powerLevelRight = get(iteConfigurationGroupRight, "PowerLevels");
      setITEPowerLevelRight(powerLevelRight);

      const shellColourRight = get(iteConfigurationGroupRight, "ShellColours");
      setITEShellColourRight(shellColourRight);

      const faceplateColourRight = get(
        iteConfigurationGroupRight,
        "FaceplateColours"
      );
      setITEFaceplateColourRight(faceplateColourRight);
    }
  }, [iteConfigurationGroupLeft, iteConfigurationGroupRight]);

  return (
    <div className="order-builder-main-div">
      {orderBuilderStatus === OrderBuilderStatus.LOADING && <Loading />}
      {orderBuilderStatus === OrderBuilderStatus.SUCCESS && (
        <div>
          <h1>Configure your {itemName}</h1>
          <p>{pageDescription}</p>
          <div>
            <SelectSides
              leftSelected={leftSelected}
              setLeftSelected={setLeftSelected}
              rightSelected={rightSelected}
              setRightSelectedSides={setRightSelectedSides}
            />
            <Graph
              leftSelected={leftSelected}
              rightSelected={rightSelected}
              errorMessageRight={errorMessageRight}
              errorMessageLeft={errorMessageLeft}
              rightAudiogram={rightAudiogram}
              setRightAudiogram={setRightAudiogram}
              manualVent={manualVent}
              setManualVent={setManualVent}
              leftAudiogram={leftAudiogram}
              setLeftAudiogram={setLeftAudiogram}
            />
            <EarImpression
              leftSelected={leftSelected}
              rightSelected={rightSelected}
              setEarImpressionForOrder={setEarImpressionForOrder}
            />
            <ITEPerformanceLevel
              itePerformanceLevelCodes={itePerformanceLevelCodes}
              setITEPerformanceLevelForOrder={setITEPerformanceLevelForOrder}
            />
            <ITEModel
              iteModelTypeCodes={iteModelTypeCodes}
              setITEModelForOrder={setITEModelForOrder}
              leftSelected={leftSelected}
              rightSelected={rightSelected}
              iteModelLinked={iteModelLinked}
              setITEModelLinked={setITEModelLinked}
              setSelectedModelRight={setSelectedModelRight}
              setSelectedModelLeft={setSelectedModelLeft}
            />
            {(iteShellStyleLeft || iteShellStyleRight) && (
              <ITEShellStyle
                iteShellStyleLeft={iteShellStyleLeft}
                iteShellStyleRight={iteShellStyleRight}
                selectedShellStyleRight={selectedShellStyleRight}
                setSelectedShellStyleRight={setSelectedShellStyleRight}
                selectedShellStyleLeft={selectedShellStyleLeft}
                setSelectedShellStyleLeft={setSelectedShellStyleLeft}
                leftSelected={leftSelected}
                rightSelected={rightSelected}
                iteModelLinked={iteModelLinked}
              />
            )}
            {(itePowerLevelLeft || itePowerLevelRight) && (
              <ITEPowerLevel
                itePowerLevelRight={itePowerLevelRight}
                itePowerLevelLeft={itePowerLevelLeft}
                activeRightITEPowerLevel={activeRightITEPowerLevel}
                setActiveRightITEPowerLevel={setActiveRightITEPowerLevel}
                activeLeftITEPowerLevel={activeLeftITEPowerLevel}
                setActiveLeftITEPowerLevel={setActiveLeftITEPowerLevel}
                leftSelected={leftSelected}
                rightSelected={rightSelected}
                iteModelLinked={iteModelLinked}
              />
            )}
            {(iteShellColourLeft || iteShellColourRight) && (
              <ITEShellColour
                iteShellColourRight={iteShellColourRight}
                iteShellColourLeft={iteShellColourLeft}
                iteFaceplateColourLeft={iteFaceplateColourLeft}
                iteFaceplateColourRight={iteFaceplateColourRight}
                activeRightITEShellColour={activeRightITEShellColour}
                setActiveRightITEShellColour={setActiveRightITEShellColour}
                activeLeftITEShellColour={activeLeftITEShellColour}
                setActiveLeftITEShellColour={setActiveLeftITEShellColour}
                activeRightITEFaceplate={activeRightITEFaceplate}
                setActiveRightITEFaceplate={setActiveRightITEFaceplate}
                activeLeftITEFaceplate={activeLeftITEFaceplate}
                setActiveLeftITEFaceplate={setActiveLeftITEFaceplate}
                leftSelected={leftSelected}
                rightSelected={rightSelected}
                iteModelLinked={iteModelLinked}
              />
            )}
            {(customOptionsGroupLeft || customOptionsGroupRight) && (iteModelForOrder && (iteModelForOrder.right || iteModelForOrder.left)) && (
              <ITECustomOptions
                customOptionsGroupLeft={customOptionsGroupLeft}
                customOptionsGroupRight={customOptionsGroupRight}
                setITECustomOptionsForOrder={setITECustomOptionsForOrder}
                leftSelected={leftSelected}
                rightSelected={rightSelected}
                iteModelLinked={iteModelLinked}
                manualVent={manualVent}
              />
            )}
            <div className="item-builder-add-to-cart-button order-builder-component">
              {errorMessages && errorMessages.length > 0 && (
                <Row className="order-builder-component-title">
                  <Col xs={12} className="alert alert-danger">
                    <p>Please resolve the following errors</p>
                    <ul>
                      {errorMessages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              )}
              <div className="order-builder-component-title">
                <a className="btn float-right" onClick={startAddOrderToCart}>
                  Add to order
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ITEItemBuilder;
