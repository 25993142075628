// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { FILE_DOWNLOAD, LOAD_ORDER_DETAIL_DATA } from './actions';
import { OrderDetailStatus } from './constants';
import api from '../../../utilities/api';
import { get } from "lodash";
import Cookies from 'universal-cookie';

export function* rootSaga() {
  yield takeLatest(LOAD_ORDER_DETAIL_DATA, loadOrderDetail);
  yield takeLatest(FILE_DOWNLOAD, fileDowload);
}

export function* loadOrderDetail(orderIdPayload) {
  try {
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.LOADING));
    const orderId = get(orderIdPayload, "payload", {});

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    const response = yield call(api.get, `/api/order/${orderId}`, {headers:headerParams});
    //console.log("Order Detail", response.data);
    yield put(actionCreators.setOrderDetailData(response.data));
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.ERROR));
  }
}

export function* fileDowload(orderIdPayload) {
  try {
    
    // yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.LOADING));

    const orderId = get(orderIdPayload, 'payload', {});
    const fileName = get(orderIdPayload, '0', {});

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    const response = yield call(api.get, `/api/order/${orderId}/file/${fileName}`, { headers: headerParams });
    // console.log("Response received: ", response);

    if (response.data) {
      const blob = new Blob([response.data], { type: 'application/octet-stream' }); // Adjust the type if known
      const downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName; // Adjust if you need to set a specific file name
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } else {
      throw new Error('File data is empty');
    }
    // yield put(actionCreators.setOrderDetailData(response.data));
    // yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.ERROR));
  }
}