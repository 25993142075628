import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { syncHistoryWithStore } from "react-router-redux";
import store from "./store";
import browserHistory from "./history";
import PrivateLayout from "../core/layouts/private/private-layout";
import Login from "../modules/login/login.container";
import ForgotPassword from "../modules/forgotPassword/forgotPassword.container";
import ResetPassword from "../modules/resetPassword/resetPassword.container";
import { Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import(/* webpackChunkName: "main-app" */ "./app.scss");

/* Import fonts + Index styling */
import("~/styles/fonts/RotisSans/bold/bold.css");
import("~/styles/fonts/RotisSans/regular/regular.css");
import("~/styles/fonts/RotisSans/light/light.css");
import("~/styles/fonts/RotisSans/semiBold/semiBold.css");
import("~/styles/_index.scss?v=2");

const history = syncHistoryWithStore(browserHistory, store);

/*
  Use this file to setup which layout / Router Switch component to use
  Say for example you have a different Router for Mobile + Web or if you have auth info in redux and show
  Have auth info in redux and use that to render Routes / Redirects based on redux state
*/

const AppComponent = () => {


  return (
    <div className="app-root">
      <Router history={history}>
        <Switch>
          <Route path="/reset-password/:code+" component={ResetPassword} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/login" component={Login} />
          <Route path="/" component={PrivateLayout} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
};

export default AppComponent;
