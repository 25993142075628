const config ={
   

    // PHONAK
        siteName:"PHONAK"

        // PROD API
        //,apiBaseURL: 'https://store.phonak.co.nz/API/',
        
        // DEV API
         ,apiBaseURL: 'https://phonak.dev.s06.system7.co.nz/API',

        // LOCAL API
        // ,apiBaseURL: 'https://localhost:5001/',
        // ,apiBaseURL: 'http://localhost:5000/',

    // UNITRON SETTINGS
        //   siteName:"UNITRON"

        // PROD API
        //  ,apiBaseURL: 'https://store.unitron.co.nz/API/',

        // LOCAL API
        // ,apiBaseURL: 'https://localhost:5001/',
        // ,apiBaseURL: 'http://localhost:5000/',
        
    // TODO: Setup so pulls from .env (below should work)
        // apiBaseURL: process.env.REACT_APP_API ?? ""

}
export default config;
