import { connect } from 'react-redux';

import FamilyCategory from './familyCategory';
import { getCategoriesStatus, getCategoriesData, getOrderStatus } from '../../store/selectors';
import { loadCategories } from '../../store/action-creators';

const mapStateToProps = (state) => ({
  categoriesStatus: getCategoriesStatus(state),
  categoriesData: getCategoriesData(state),
  orderStatus: getOrderStatus(state), 
});

const mapDispatchToProps = {
  loadCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyCategory);