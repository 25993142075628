import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const RepairOrderDetail = ({ data }) => {
  const reasons = data.ReasonForReshellOrRemake ? JSON.parse(data.ReasonForReshellOrRemake) : {};;

  useEffect(() => {
    // console.log(reasons);
  }, [reasons]);

  return (
    <table className="w-100 mt-5">
      <tbody>
        <tr>
          <th>Hearing Aid Model:</th>
          <td>
            <div className="row">
              <div className="col-6">
                <label htmlFor="RightEar">Right Ear</label>
                <Form.Control
                  type="text"
                  name="rightEar"
                  className="me-2"
                  aria-label="rightEar"
                  value={data.RightModelCode}
                  readOnly
                />
              </div>
              <div className="col-6">
                <label htmlFor="LeftEar">Left Ear</label>
                <Form.Control
                  type="text"
                  name="leftEar"
                  className="me-2"
                  aria-label="leftEar"
                  value={data.LeftModelCode}
                  readOnly
                />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Custom Earmould/Shell S/N:</th>
          <td>
            <div className="row">
              <div className="col-6">
                <label htmlFor="earmouldShellRight">Right Ear</label>
                <Form.Control
                  type="text"
                  name="earmouldShellRight"
                  className="me-2"
                  aria-label="earmouldShellRight"
                  value={data.RightSerialNumber}
                  readOnly
                />
              </div>
              <div className="col-6">
                <label htmlFor="earmouldShellLeft">Left Ear</label>
                <Form.Control
                  type="text"
                  name="earmouldShellLeft"
                  className="me-2"
                  aria-label="earmouldShellLeft"
                  value={data.LeftSerialNumber}
                  readOnly
                />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Accessory item count</th>
          <td>
            <Form.Control
              type="number"
              name="Accessories"
              className="me-2 number-input"
              aria-label="Accessories"
              min="1"
              max="5"
              value={data.AccessoriesCount}
              readOnly
            />
          </td>
        </tr>
        <tr>
          <th>Roger item count</th>
          <td>
            <Form.Control
              type="number"
              name="Roger"
              className="me-2 number-input"
              aria-label="Roger"
              min="1"
              max="5"
              value={data.RogerCount}
              readOnly
            />
          </td>
        </tr>
        <tr>
          <th>Charger item count</th>
          <td>
            <Form.Control
              type="number"
              name="ChargerOptions"
              className="me-2 number-input"
              aria-label="ChargerOptions"
              min="1"
              max="5"
              value={data.ChargerOptionsCount}
              readOnly
            />
          </td>
        </tr>
        <tr>
          <h2>Repair</h2>
        </tr>
        <tr>
          <th>Reason for repair</th>
          <td>
            <textarea
              id="ReasonForRepair"
              name="ReasonForRepair"
              rows="8"
              cols="100"
              value={data.ReasonForRepair}
              readOnly
            ></textarea>
          </td>
        </tr>
        <tr>
          <h2>Reshell or Remake</h2>
        </tr>
        <tr>
          <th>Reason for Reshell or Remake</th>
          <td>
            <div className="row">
              <div className="col-6">
                <div className="row reshell-or-remake-checkboxes">
                  <div className="col-12">
                    <input
                      type="checkbox"
                      id="FitRetention"
                      checked={reasons.FitRetention}
                      readOnly
                    />
                    <label htmlFor="FitRetention">Fit - Retention</label>
                  </div>
                  <div className="col-12">
                    <input type="checkbox" id="FitTight" checked={reasons.FitTight} readOnly />
                    <label htmlFor="FitTight">Fit - Tight</label>
                  </div>
                  <div className="col-12">
                    <input type="checkbox" id="Feedback" checked={reasons.Feedback} readOnly />
                    <label htmlFor="Feedback">Feedback</label>
                  </div>
                  <div className="col-12">
                    <input
                      type="checkbox"
                      id="DifficultyWithInsertion"
                      checked={reasons.DifficultyWithInsertion}
                      readOnly
                    />
                    <label htmlFor="DifficultyWithInsertion">
                      Difficulty with Insertion
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="checkbox"
                      id="CosmeticReasons"
                      checked={reasons.CosmeticReasons}
                      readOnly
                    />
                    <label htmlFor="CosmeticReasons">Cosmetic Reasons</label>
                  </div>
                  <div className="col-12">
                    <input type="checkbox" id="Broken" checked={reasons.Broken} readOnly />
                    <label htmlFor="Broken">Broken</label>
                  </div>
                </div>
              </div>
              <div className="col-6 reshell-or-remake-checkboxes">
                <input
                  type="checkbox"
                  id="CurrentWearer"
                  checked={data.IsCurrentPhonakHearingAidWearer}
                  readOnly
                />
                <label htmlFor="CurrentWearer">
                  Current Phonak Hearing aid Wearer?
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>Additional Information</th>
          <td>
            <textarea
              id="AdditionalInformation"
              name="AdditionalInformation"
              rows="8"
              cols="100"
              value={data.AdditionalInformation}
              readOnly
            ></textarea>
          </td>
        </tr>
        <tr>
          <th>Quote required for Specific funding type:</th>
          <td>
            <div className="row reshell-or-remake-checkboxes">
              <div className="col-12">
                <input type="checkbox" id="Enable" checked={data.IsEnable} readOnly />
                <label htmlFor="Enable">
                  {`Enable (H.Aid is 5+ years old) - Please quote if the repair cost is more than $235 per H.Aid`}
                </label>
              </div>
              <div className="col-12">
                <input
                  type="checkbox"
                  id="VeteransAffairs"
                  checked={data.IsVeteransAffairsClient}
                  readOnly
                />
                <label htmlFor="VeteransAffairs">
                  {`Veterans Affairs (H.Aid is 5+ years old) - Please quote if the repair cost is more than $277 per H.Aid`}
                </label>
              </div>
              <div className="col-12">
                <input type="checkbox" id="Insurance" checked={data.IsInsurance} readOnly />
                <label htmlFor="Insurance">
                  Insurance - Please provide a quote before any repair is carried out
                </label>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RepairOrderDetail;
