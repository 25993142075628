import { connect } from 'react-redux';

import StockOrders from './stockOrders';
import { getCategoriesStatus, getCategoriesData, getOrderStatus } from '../../store/selectors';
import { getCartData } from '../../../orderBuilder/store/selectors';
import { loadCategories, setOrderStatus } from '../../store/action-creators';
import { addOrderToCart } from '../../../orderBuilder/store/action-creators';

const mapStateToProps = (state) => ({
  categoriesStatus: getCategoriesStatus(state),
  categoriesData: getCategoriesData(state),
  cartData: getCartData(state),
  orderStatus: getOrderStatus(state),
});

const mapDispatchToProps = {
  loadCategories,
  addOrderToCart,
  setOrderStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(StockOrders);